<template>
  <div>
    <div v-for="(partner, index) in partners" :key="index" class="row g-2">
      <form-input
        className="col-12 col-md-6 form-input"
        label="Nome"
        v-model="partner.name"
        :id="`partners[${index}].name`"
        :name="`partners[${index}].name`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 col-md-6 form-input"
        label="Telefone"
        v-model="partner.phone"
        :id="`partners[${index}].phone`"
        :name="`partners[${index}].phone`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 col-md-12 form-input"
        label="CPF"
        v-model="partner.cpf"
        :id="`partners[${index}].cpf`"
        :name="`partners[${index}].cpf`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 col-md-6 form-input"
        label="E-mail"
        v-model="partner.email"
        :id="`partners[${index}].email`"
        :name="`partners[${index}].email`"
        type="text"
        :isDisabled="isDisabled"
      />
      <form-input
        className="col-12 col-md-6 form-input"
        label="Participação (%)"
        v-model="partner.percentage"
        :id="`partners[${index}].percentage`"
        :name="`partners[${index}].percentage`"
        type="text"
        :isDisabled="isDisabled"
      />

      <form-button
        class="col-12 form-input"
        buttonColor="fifth mt-0 mb-4"
        title="Remover Sócio"
        :isDisabled="isDisabled"
        @click.prevent="removePartner(index)"
      />
    </div>

    <div v-if="partners.length > 0" class="row">
      <div class="text-center">
        <img class="line-svg" :src="loadAsset('icon/svg/line_add.svg')" />
      </div>
    </div>
    <form-button
      class="col-12 form-input mt-0"
      buttonColor="secondary mt-1"
      title="Adicionar Sócio"
      :isDisabled="isDisabled"
      @click.prevent="addNewPartner"
    />
  </div>
</template>
<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { reactive } from 'vue'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'

export default {
  name: 'appBusinessPartners',

  components: {
    formInput,
    formButton
  },
  props: {
    businessPartners: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const partners = reactive([...props.businessPartners])

    function addNewPartner() {
      partners.push({
        name: '',
        phone: '',
        email: '',
        percentage: ''
      })
      emit('businessPartners', partners)
    }

    function removePartner(index) {
      partners.splice(index, 1)
      emit('businessPartners', partners)
    }

    return {
      partners,
      addNewPartner,
      removePartner,
      loadAsset
    }
  }
}
</script>

<style lang="scss">
.form-input {
  button {
    &.fifth {
      color: var(--sixth-color);
      border-color: var(--fifth-color);
      background-color: var(--fifth-color);

      &:hover,
      &:focus {
        color: var(--sixth-color);
        border-color: var(--fifth-color);
        background-color: var(--fifth-color);
      }
    }
  }
}
</style>
