<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { formatISO, addYears } from 'date-fns'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import { configPromo } from '@/composables/utils/moneyConfig'
import { useInstitution } from '@/composables/utils/useInstitution'
import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'formModalPromoCode',

  components: {
    formInput,
    formSwitch,
    formMoney,
    formSelect,
    formDate,
    formButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    const { fetchInstitutionOptions } = useInstitution()

    const topicOptions = fetchInstitutionOptions({ includeAll: true })

    const clientTypesOptions = ref([
      { key: 'PF', name: 'PF' },
      { key: 'PJ', name: 'PJ' },
      { key: 'PJ_PF', name: 'PF & PJ' }
    ])

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        code: yup.string().required(),
        spreadPercentage: yup.string().test((value) => value !== '0.00'),
        taxPercentage: yup.string().test((value) => value !== '0.00'),
        description: yup.string().required(),
        clientType: yup.string().required(),
        institution: yup.string().required()
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      const currentDate = new Date()
      const defaultBeginsAt = formatISO(currentDate, { representation: 'complete' })
      const defaultEndsAt = formatISO(addYears(currentDate, 3000), { representation: 'complete' })

      const body = {
        ...props.initialFormData,
        code: codeValue.value,
        beginsAt: beginsAtValue.value || defaultBeginsAt,
        endsAt: endsAtValue.value || defaultEndsAt,
        spreadPercentageValue: spreadPercentageValue.value,
        taxPercentageValue: taxPercentageValue.value,
        description: descriptionValue.value,
        hasIndicateAndWin: hasIndicateAndWin.value,
        hasGiftCard: hasGiftCard.value,
        clientType: clientTypeValue.value,
        institution: institutionValue.value
      }
      emit('onSubmitted', body)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    const codeValue = ref('')
    const beginsAtValue = ref('')
    const endsAtValue = ref('')
    const spreadPercentageValue = ref('')
    const taxPercentageValue = ref('')
    const descriptionValue = ref('')
    const hasIndicateAndWin = ref(false)
    const hasGiftCard = ref(false)
    const clientTypeValue = ref('')
    const institutionValue = ref('')

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        codeValue.value = newFormData.code
        beginsAtValue.value = newFormData.beginsAt
        endsAtValue.value = newFormData.endsAt
        spreadPercentageValue.value = newFormData.spreadPercentageValue
        taxPercentageValue.value = newFormData.taxPercentageValue
        descriptionValue.value = newFormData.description
        hasIndicateAndWin.value = newFormData.hasIndicateAndWin
        hasGiftCard.value = newFormData.hasGiftCard
        clientTypeValue.value = newFormData.clientType
        institutionValue.value = newFormData.institution
      },
      { immediate: true }
    )

    return {
      contentActive,

      codeValue,
      beginsAtValue,
      endsAtValue,
      spreadPercentageValue,
      taxPercentageValue,
      descriptionValue,
      hasIndicateAndWin,
      hasGiftCard,
      clientTypeValue,
      institutionValue,

      topicOptions,
      clientTypesOptions,

      onSubmitAction,
      close,
      hasInputAllowed,

      configPromo
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
      </div>

      <div class="col-12">
        <div class="row g-2">
          <form-input
            className="col-12 form-input"
            label="Código"
            v-model="codeValue"
            id="code"
            name="code"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-date
            class="col-12 form-input"
            label="Inicio"
            v-model="beginsAtValue"
            id="beginsAt"
            name="beginsAt"
            icon="bi bi-calendar-week"
          />

          <form-date
            class="col-12 form-input"
            label="Fim"
            v-model="endsAtValue"
            id="endsAt"
            name="endsAt"
            icon="bi bi-calendar-week"
          />

          <form-money
            className="col-12 form-input"
            v-model="spreadPercentageValue"
            label="Spread"
            id="spreadPercentage"
            name="spreadPercentage"
            :config="configPromo"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            className="col-12 form-input"
            v-model="taxPercentageValue"
            label="Tarifa"
            id="taxPercentage"
            name="taxPercentage"
            :config="configPromo"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Descrição"
            v-model="descriptionValue"
            id="description"
            name="description"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Destino"
            v-model="institutionValue"
            :items="topicOptions"
            id="institution"
            name="institution"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Tipo"
            v-model="clientTypeValue"
            :items="clientTypesOptions"
            id="clientType"
            name="clientType"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            v-if="institutionValue === 'TRANSFERBANK'"
            className="col-12 form-input"
            label="Gift"
            description="Ativar/Desativar GiftCard"
            v-model="hasGiftCard"
            id="hasGiftCard"
            name="hasGiftCard"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            v-if="institutionValue === 'TRANSFERBANK' && clientTypeValue === 'PJ'"
            className="col-12 form-input"
            label="I e G"
            description="Ativar/Desativar Indique e Ganhe"
            v-model="hasIndicateAndWin"
            id="hasIndicateAndWin"
            name="hasIndicateAndWin"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 44%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -44%;
    padding: 44px 38px;

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
