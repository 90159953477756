<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { format, parseISO } from 'date-fns'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch, computed } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSelect from '@/components/form/select.vue'
import formSwitch from '@/components/form/switch.vue'
import formTextarea from '@/components/form/textarea.vue'
import appUsers from '@/components/modalClient/_addUsers.vue'
import appFinancialInstitution from '@/components/modalClient/_financialInstitution.vue'
import appTableDiscounts from '@/components/shared/tableDiscounts.vue'
import appTitle from '@/components/shared/title.vue'
import {
  configCommercialMoney,
  configIr,
  configMoney,
  moneyFormat
} from '@/composables/utils/moneyConfig'
import { useInstitution } from '@/composables/utils/useInstitution'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { isEqualFloat } from '@/composables/utils/validationUtils'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'formModalPre',

  components: {
    appTitle,
    appTableDiscounts,
    appUsers,
    appFinancialInstitution,
    formInput,
    formTextarea,
    formMoney,
    formSelect,
    formSwitch,
    formButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    const cacheBrokers = useCache().getBrokers

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const filterFinancialInstitutions = (institutions) => {
      return institutions.filter(
        (institution) => institution.limitUSD > 0 || institution.usedLimitUSD > 0
      )
    }

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        clientName: yup.string().required(),
        phone: yup.string().required()
      })
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const onSubmit = handleSubmit(async () => {
      if (editModeEnabled.value !== true) {
        const body = {
          name: clientNameValue.value,
          phone: phoneValue.value,
          institution: institutionValue.value,
          onboardingStatus: statusValue.value,
          spreadType: spreadTypeValue.value,
          spread: spreadValue.value,
          clientTax: parseFloat(clientTaxValue.value),

          users: users.value,
          allowedFinancialInstitutions: filterFinancialInstitutions(
            allowedFinancialInstitutions.value
          ),

          bs2Iban: bs2IbanValue.value,
          transferbankAccount: transferbankAccount.value,

          financialInstitution: financialInstitution.value,
          diaryLimitUSD: parseFloat(diaryLimitUSDValue.value),

          adminObservation: adminObservationValue.value
        }
        emit('onSubmitted', body)
      } else {
        const body = {
          ...props.initialFormData,
          clientName: clientNameValue.value,
          phone: phoneValue.value,
          institution: institutionValue.value,
          onboardingStatus: statusValue.value,
          spreadType: spreadTypeValue.value,
          spread: spreadValue.value,
          clientTax: parseFloat(clientTaxValue.value),

          users: users.value,
          bs2Iban: bs2IbanValue.value,
          brazaIban: brazaIbanValue.value,
          brazaAccount: brazaAccountValue.value,
          transferbankAccount: transferbankAccount.value,
          isActive: isActiveValue.value,

          allowedFinancialInstitutions: filterFinancialInstitutions(
            allowedFinancialInstitutions.value
          ),

          financialInstitution: financialInstitution.value,
          diaryLimitUSD: parseFloat(diaryLimitUSDValue.value),

          adminObservation: adminObservationValue.value,

          userDefaultSignerId: userDefaultSignerIdValue.value
        }
        emit('onSubmitted', body)
      }
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
      fetchFinancialInstitutions()
    })

    const editModeEnabled = ref(false)

    const clientNameValue = ref('')
    const clientIdValue = ref('')
    const phoneValue = ref('')
    const demandValue = ref('')

    const { fetchInstitutionOptions } = useInstitution()

    const institutionOptions = fetchInstitutionOptions({ includeAll: false })

    const institutionValue = ref('TRANSFERBANK')
    const bs2IbanValue = ref('')
    const brazaIbanValue = ref('')
    const brazaAccountValue = ref('')
    const transferbankAccount = ref('')
    const userDefaultSignerIdValue = ref('')

    const statusValue = ref('')
    const clientTaxValue = ref('')

    const statusOptions = ref([
      { key: 'PENDING', name: 'Pendente' },
      { key: 'APPROVED', name: 'Aprovado' },
      { key: 'WAITING_DOCS', name: 'Aguardando Documentos' },
      { key: 'WAITING_APPROVED', name: 'Aguardando Aprovação' }
    ])

    const spreadTypeValue = ref('')
    const spreadTypeOptions = ref([
      { key: 'PERCENTAGE', name: 'Percentual (%)' },
      { key: 'MONETARY', name: 'Monetário (R$)' }
    ])

    const spreadValue = ref('')
    const adminObservationValue = ref('')

    const users = ref({})

    const financialInstitution = ref('')
    const allowedFinancialInstitutions = ref([])

    const fetchFinancialInstitutions = () => {
      const institutionsFromApi = cacheBrokers.map((institution) => ({
        institution: institution.id,
        allowed: false
      }))

      const combinedInstitutions = populateFinancialInstitutions(institutionsFromApi)
      allowedFinancialInstitutions.value = combinedInstitutions
    }

    const populateFinancialInstitutions = (apiInstitutions) => {
      const institutionsMap = new Map()

      allowedFinancialInstitutions.value.forEach((institution) => {
        institutionsMap.set(institution.institution, { ...institution })
      })

      apiInstitutions.forEach((institution) => {
        if (institutionsMap.has(institution.institution)) {
          institutionsMap.set(institution.institution, {
            ...institution,
            ...institutionsMap.get(institution.institution)
          })
        } else {
          institutionsMap.set(institution.institution, { ...institution })
        }
      })

      return Array.from(institutionsMap.values())
    }

    const diaryLimitUSDValue = ref('')
    const usedDiaryLimitUSD = ref('')
    const totalLimitUSD = ref('')
    const totalUsedLimitUSD = ref('')
    const onboardedAt = ref('')
    const partnerCode = ref('')
    const discounts = ref({})
    const isActiveValue = ref(false)

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true

        isActiveValue.value = newFormData.isActive
        clientNameValue.value = newFormData.clientName
        partnerCode.value = newFormData.partnerCode
        clientIdValue.value = newFormData.clientId
        phoneValue.value = newFormData.phone
        demandValue.value = newFormData.demand
        discounts.value = newFormData.discounts
        institutionValue.value = newFormData.institution
        bs2IbanValue.value = newFormData.bs2Iban
        brazaIbanValue.value = newFormData.brazaIban
        brazaAccountValue.value = newFormData.brazaAccount
        transferbankAccount.value = newFormData.transferbankAccount
        statusValue.value = newFormData.onboardingStatus
        spreadTypeValue.value = newFormData.spreadType
        spreadValue.value = newFormData.spread
        clientTaxValue.value = newFormData.clientTax

        const createdAt = parseISO(newFormData.onboardedAt)
        onboardedAt.value = newFormData.onboardedAt ? format(createdAt, 'dd/MM/yyyy HH:mm') : ''

        diaryLimitUSDValue.value = newFormData.diaryLimitUSD
        usedDiaryLimitUSD.value = newFormData.usedDiaryLimitUSD

        totalLimitUSD.value = newFormData.annualLimitUSD
        totalUsedLimitUSD.value = newFormData.usedAnnualDiaryLimitUSD

        users.value = newFormData.users
        allowedFinancialInstitutions.value = newFormData.allowedFinancialInstitutions
        financialInstitution.value = newFormData.financialInstitution

        adminObservationValue.value = newFormData.adminObservation

        userDefaultSignerIdValue.value = newFormData.userDefaultSignerId
      },
      { immediate: true }
    )

    const isBS2Allowed = computed(() => {
      return allowedFinancialInstitutions.value.find((item) => item.institution === 'BS2')?.allowed
    })

    const isBrazaAllowed = computed(() => {
      return allowedFinancialInstitutions.value.find((item) => item.institution === 'BRAZA')
        ?.allowed
    })

    const isTransferBankAllowed = computed(() => {
      return allowedFinancialInstitutions.value.find((item) => item.institution === 'TRANSFERBANK')
        ?.allowed
    })

    const financialInstitutionOptions = computed(() => {
      return allowedFinancialInstitutions.value
        .filter((item) => item.allowed === true)
        .map((item) => ({ key: item.institution, name: item.institution }))
    })

    const usedPercentageTotal = computed(() => {
      if (totalLimitUSD.value === 0) return 0
      return ((totalUsedLimitUSD.value / totalLimitUSD.value) * 100).toFixed(2)
    })

    const usedPercentageTotalDay = computed(() => {
      if (isEqualFloat(diaryLimitUSDValue.value, 0, 2)) return 0
      return ((usedDiaryLimitUSD.value / diaryLimitUSDValue.value) * 100).toFixed(2)
    })

    const userDefaultSignerIdOptions = users.value.map((user) => ({
      key: user.id,
      name: user.userName
    }))

    if (userDefaultSignerIdOptions.length === 1) {
      userDefaultSignerIdValue.value = userDefaultSignerIdOptions[0].key
    }

    return {
      contentActive,
      clientNameValue,
      partnerCode,
      onboardedAt,
      clientIdValue,
      phoneValue,
      demandValue,
      discounts,
      institutionValue,
      institutionOptions,
      bs2IbanValue,
      brazaIbanValue,
      brazaAccountValue,
      transferbankAccount,
      financialInstitution,
      financialInstitutionOptions,

      spreadTypeValue,
      spreadTypeOptions,
      configCommercialMoney,
      configIr,
      spreadValue,
      clientTaxValue,
      configMoney,
      moneyFormat,
      diaryLimitUSDValue,
      usedDiaryLimitUSD,

      users,
      allowedFinancialInstitutions,
      totalLimitUSD,
      totalUsedLimitUSD,
      usedPercentageTotal,
      usedPercentageTotalDay,

      isBS2Allowed,
      isBrazaAllowed,
      isTransferBankAllowed,

      adminObservationValue,

      statusValue,
      statusOptions,

      editModeEnabled,
      onSubmitAction,
      close,
      hasInputAllowed,
      isActiveValue,
      userDefaultSignerIdValue,
      userDefaultSignerIdOptions
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
        <div v-if="editModeEnabled" class="col-4 text-end">
          <h5>
            <span class="cod">
              CÓDIGO: <b>{{ partnerCode }}</b>
            </span>
          </h5>
          <p>{{ onboardedAt }}</p>
        </div>
      </div>

      <div class="col-12">
        <div class="row g-2">
          <form-input
            className="col-12 form-input"
            label="Nome do cliente"
            v-model="clientNameValue"
            id="clientName"
            name="clientName"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="CPF/CNPJ"
            v-model="clientIdValue"
            id="clientId"
            name="clientId"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            className="col-12 form-input"
            label="Telefone"
            v-model="phoneValue"
            id="phone"
            name="phone"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            label="Demanda de Câmbio informada pelo cliente:"
            v-model="demandValue"
            id="demand"
            name="demand"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-select
            class="col-12 form-input"
            label="Instituição a qual pertence"
            v-model="institutionValue"
            :items="institutionOptions"
            id="operationType"
            name="operationType"
            :isDisabled="!hasInputAllowed()"
          />

          <form-switch
            className="col-12 form-input"
            description="Cliente ativo?"
            v-model="isActiveValue"
            id="isActive"
            name="isActive"
            :isDisabled="!hasInputAllowed()"
          />

          <app-table-discounts v-if="discounts?.length > 0" :discounts="discounts" />

          <app-financial-institution
            v-if="allowedFinancialInstitutions?.length > 0"
            :financialInstitution="allowedFinancialInstitutions"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="isBrazaAllowed"
            className="col-12 form-input"
            label="Iban BRAZA"
            v-model="brazaIbanValue"
            id="ibanBraza"
            name="ibanBraza"
            type="text"
          />

          <form-input
            v-if="isBrazaAllowed"
            className="col-12 form-input"
            label="Account BRAZA"
            v-model="brazaAccountValue"
            id="brazaAccount"
            name="brazaAccount"
            type="text"
          />

          <form-input
            v-if="isBS2Allowed"
            className="col-12 form-input"
            label="Iban BS2"
            v-model="bs2IbanValue"
            id="iban"
            name="iban"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="isTransferBankAllowed"
            className="col-12 form-input"
            label="Account TRANSFERBANK"
            v-model="transferbankAccount"
            id="transferbankAccount"
            name="transferbankAccount"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            v-if="financialInstitutionOptions?.length > 0"
            class="col-12 form-input"
            label="Escolha o canal bancário"
            v-model="financialInstitution"
            :items="financialInstitutionOptions"
            id="financialInstitution"
            name="financialInstitution"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title label="OPERAÇÕES DE CÂMBIO" />

          <form-money
            className="col-12 col-md-6 form-input"
            v-model="totalLimitUSD"
            label="Limite anual"
            id="totalLimitUSD"
            name="totalLimitUSD"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="true"
          />

          <form-money
            className="col-12 col-md-6 form-input"
            v-model="totalUsedLimitUSD"
            label="Limite anual já utilizado"
            id="totalUsedLimitUSD"
            name="totalUsedLimitUSD"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="true"
          />

          <div class="mb-4">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: usedPercentageTotal + '%' }"
                aria-valuenow="usedPercentageTotal"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ usedPercentageTotal }}%
              </div>
            </div>
          </div>

          <form-money
            className="col-12 col-md-6 form-input"
            v-model="diaryLimitUSDValue"
            label="Limite Diário"
            id="diaryLimitUSD"
            name="diaryLimitUSD"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            className="col-12 col-md-6 form-input"
            v-model="usedDiaryLimitUSD"
            label="Limite Diário já utilizado"
            id="usedDiaryLimitUSD"
            name="usedDiaryLimitUSD"
            :config="moneyFormat('USD ', '.', ',', 2)"
            :isDisabled="true"
          />

          <div class="mb-4">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: usedPercentageTotalDay + '%' }"
                aria-valuenow="usedPercentageTotalDay"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ usedPercentageTotalDay }}%
              </div>
            </div>
          </div>

          <app-title label="CONFIGURAÇÃO SPREAD / TARIFA" />

          <form-select
            class="col-12 col-md-4 form-input"
            label="Tipo de spread"
            v-model="spreadTypeValue"
            :items="spreadTypeOptions"
            id="spreadType"
            name="spreadType"
          />

          <form-money
            class="col-12 col-md-4 form-input"
            v-model="spreadValue"
            label="Spread"
            id="spread"
            name="spread"
            :config="spreadTypeValue == 'PERCENTAGE' ? configIr : configCommercialMoney"
          />

          <form-money
            class="col-12 col-md-4 form-input"
            v-model="clientTaxValue"
            label="Tarifa"
            id="clientTax"
            name="clientTax"
            :config="moneyFormat('USD ', '.', ',', 2)"
          />

          <app-users
            :clientId="clientIdValue"
            :users="users"
            @users="users = $event"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Responsalvel pela assinatura"
            v-model="userDefaultSignerIdValue"
            :items="userDefaultSignerIdOptions"
            id="userDefaultSignerId"
            name="userDefaultSignerId"
            :isDisabled="!hasInputAllowed()"
          />

          <form-textarea
            className="col-12 form-input mt-3"
            label="Observações"
            v-model="adminObservationValue"
            id="adminObservation"
            name="adminObservation"
            placeholder="Coloque aqui uma observação sobre o cliente."
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            class="col-12 form-input"
            label="Status"
            v-model="statusValue"
            :items="statusOptions"
            id="status"
            name="status"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.box-content {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  padding: 0;

  .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .content {
    background-color: var(--body-background);
    transition: right 0.3s;
    overflow: auto;
    height: 100%;
    width: 44%;
    z-index: 1;
    position: fixed;
    top: 0;
    right: -44%;
    padding: 44px 38px;

    .cod {
      b {
        color: #e5b93c;
      }
    }

    .progress-bar {
      font-size: 8px;
    }

    .table-discounts {
      color: aliceblue;
      font-size: 9px;

      table {
        width: 100%;

        thead {
          background-color: black;
          color: white;

          th {
            padding: 6px;
            text-align: center;

            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              text-align: left;
            }

            &:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              text-align: right;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: center;
              padding: 6px;

              &:first-child {
                text-align: left;
              }

              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }

    &.active {
      right: 0;
    }

    h4 {
      font-size: 36px;
      font-weight: 500;
    }
  }
}
</style>
