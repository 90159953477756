<template>
  <div class="modal-th d-flex justify-content-center align-items-center" :class="modalConfig.type">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['modal-content-th col-12 col-md-4', { active: contentActive }]">
      <div class="d-flex justify-content-end">
        <i class="close" @click="close"></i>
      </div>
      <div class="icon text-center">
        <i :class="modalConfig.icon"></i>
      </div>
      <div class="modal-header-th">
        <h4 class="modal-title-th text-center">{{ modalConfig.title }}</h4>
      </div>
      <div class="modal-body-th d-flex justify-content-center">
        <p class="text-center col-10">{{ modalConfig.description }}</p>
        <slot></slot>
      </div>
      <div class="row g-2 d-flex justify-content-center mt-0 modal-footer-th">
        <template v-for="(field, index) in modalConfig.formSchema" :key="index">
          <form-input
            v-if="field.type === 'input'"
            className="col-10 form-input"
            :label="field.label"
            v-model="formData[field.model]"
            :id="field.model"
            :name="field.model"
            :type="field.inputType"
            :icon="field.icon"
          />

          <form-select
            v-else-if="field.type === 'select'"
            className="col-10 form-input"
            :label="field.label"
            v-model="formData[field.model]"
            :items="field.items"
            :id="field.model"
            :name="field.model"
          />
        </template>
        <div class="col-12 col-md-5 form-input">
          <button class="back" @click="close">
            {{ modalConfig.backButtonText }}
          </button>
        </div>
        <div class="col-12 col-md-5 form-input">
          <button class="action-submit secondary" @click="actionSubmit">
            {{ !loading ? modalConfig.submitButtonText : '' }}
            <app-loading v-if="loading" :color="'#fff'" :duration="'2.3s'" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import appLoading from '@/components/shared/loading.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'

export default {
  name: 'appModal',

  components: {
    appLoading,
    formInput,
    formSelect
  },
  props: {
    modalConfig: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const contentActive = ref(false)
    const scroll = ref(false)
    const formData = ref({})

    const { setNoScroll } = useNoScroll()

    const initializeFormData = () => {
      if (props.modalConfig && props.modalConfig.formSchema) {
        props.modalConfig.formSchema.forEach((field) => {
          formData.value[field.model] = field.value || ''
        })
      }
    }

    const actionSubmit = () => {
      emit('submit', { ...props.modalConfig, formData: formData.value })
    }

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
        initializeFormData()
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    return {
      actionSubmit,
      close,
      contentActive,
      formData
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/base.scss';

.modal-th {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  z-index: 1030;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  padding: 12px;
  transition: background-color 0.5s ease;

  > .back {
    background-color: rgba(0, 0, 0, 0);
    transition: background 0.36s cubic-bezier(0.32, 0.08, 0.55, 1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s;

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .modal-content-th {
    border-radius: 9px;
    padding: 12px;
    position: fixed;
    background-color: var(--body-background);
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 999;

    &.active {
      opacity: 1;
      margin-bottom: 200px;
    }

    .close {
      cursor: pointer;
      height: 17px;
      width: 17px;
      opacity: 1;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e');

      &:hover {
        opacity: 0.6;
      }
    }

    .icon {
      .bi {
        font-size: 70px;
      }
    }

    .modal-title-th {
      color: var(--label-color);
    }

    .modal-body-th {
      p {
        color: var(--label-color);
        margin: 15px 0 20px 0;
      }
    }

    .modal-footer-th {
      margin-top: 20px;

      .action-submit {
        background-color: var(--secondary-color);
      }
    }
  }

  .modal-content-th {
    .modal-footer-th {
      .action-submit {
        &.warning {
          background-color: #f1c40f;
        }
        &.success {
          background-color: #07bc0c;
        }
        &.error {
          background-color: #e74c3c;
        }
      }
    }
  }
}
</style>
