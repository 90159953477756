<script>
import {
  hasInputAllowed,
  isTransferbankAdminUser
} from '@composables/utils/useWhitelabelService.js'
import { onMounted, ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formInput from '@/components/form/input.vue'
import formModal from '@/components/shared/formModal.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePagination } from '@/composables/utils/usePagination'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchUser, createAdminUser, updateAdminUser, deleteAdminUser } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appUsers',

  components: {
    formModal,
    formInput,
    appLoading,
    appTable,
    appModal
  },

  props: {
    search: String
  },

  setup(props) {
    useMeta({ title: 'Administradores' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

    const searchValue = ref(props.search)

    const users = ref('')
    const loading = ref(false)

    const permissions = usePermissions()

    const { setNoScroll } = useNoScroll()

    useSearchThrottle(searchValue, () => {
      resetAndUpdateUsers()
    })

    const tableHeaders = [
      { key: 'userName', label: 'Nome', type: 'text' },
      { key: 'email', label: 'E-mail', type: 'text' },
      { key: 'status', label: 'Status', type: 'userStatusType' },
      { key: 'lastLoginAt', label: 'Último acesso', type: 'date', format: 'dd/MM/yyyy HH:mm' },
      { key: 'roleName', label: 'Permissão', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'edit',
            label: 'Editar',
            class: 'edit',
            emitEvent: 'edit',
            isVisible: permissions.canEditData()
          },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchUsers = async (config) => {
      loading.value = true
      try {
        const response = await fetchUser(config)
        users.value =
          currentPage.value === 0
            ? response.data.payload
            : [...users.value, ...response.data.payload]

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (!loading.value && users.value.length !== 0 && currentPage.value < totalPages.value - 1) {
        nextPage()
        updateUsersWithFilters()
      }
    })

    const resetUsers = () => {
      users.value = ''
      currentPage.value = 0
    }

    const updateUsersWithFilters = () => {
      const config = {
        page: currentPage.value,
        search: searchValue.value
      }

      const queryParams = { ...config }
      delete queryParams.page
      router.push({ name: 'Users', query: queryParams })
      fetchUsers(config)
    }

    const resetAndUpdateUsers = () => {
      resetUsers()
      updateUsersWithFilters()
    }

    onMounted(() => {
      const config = {
        page: 0,
        search: searchValue.value
      }
      fetchUsers(config)
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const rolesOptions = computed(() => {
      if (isTransferbankAdminUser()) {
        return [
          { key: 'SUPER_ADMIN', name: 'Super Admin' },
          { key: 'ADMIN', name: 'Admin' }
        ]
      } else {
        return [
          { key: 'SUPER_ADMIN_WHITELABEL', name: 'Super Admin' },
          { key: 'ADMIN_WHITELABEL', name: 'Admin' },
          { key: 'ADMIN_BANKER_WHITELABEL', name: 'Assessor' },
          { key: 'ADMIN_BROKER_WHITELABEL', name: 'Corretora' }
        ]
      }
    })

    const formSchema = [
      {
        component: 'formInput',
        name: 'userName',
        label: 'Nome',
        class: 'col-12 form-input',
        value: 'userName',
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'email',
        label: 'E-mail',
        class: 'col-12 form-input',
        value: 'email',
        rules: yup.string().required()
      },
      {
        component: 'formSelect',
        name: 'roles',
        label: 'Permissão',
        class: 'col-12 form-input',
        value: 'roles',
        items: rolesOptions.value,
        rules: yup.string().required()
      },
      {
        component: 'formSwitch',
        name: 'isActive',
        description: 'Admin ativo?',
        class: 'col-12 form-input',
        value: 'isActive',
        rules: yup.string().required()
      }
    ]

    const isAdminRole = (role) => {
      const adminKeys = rolesOptions.value
        .filter((option) => option.key.includes('ADMIN'))
        .map((option) => option.key)
      return adminKeys.includes(role)
    }

    const newUser = () => {
      title.value = 'Adicionar novo administrador'
      modalEnabled.value = true
    }

    const handleEdit = (item) => {
      title.value = 'Editar administrador'

      if (Array.isArray(item.roles) && item.roles.length > 0) {
        const nonAdminRoles = item.roles.filter((role) => !isAdminRole(role))
        const adminRole = item.roles.find((role) => isAdminRole(role))
        item.roles = adminRole ? adminRole : item.roles[0]
        item.nonAdminRoles = nonAdminRoles
      }
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitUser(formData)
      } else {
        handleUpdateUser(formData)
      }
    }

    const handleSubmitUser = async (formData) => {
      loadingButton.value = true
      try {
        const body = {
          userName: formData.userName.trim(),
          email: formData.email.trim(),
          roles: [formData.roles],
          isActive: formData.isActive
        }
        await createAdminUser(body)
        modalEnabled.value = false
        resetUsers()
        fetchUsers()
        toast('Administrador cadastrado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateUser = async (formData) => {
      if (typeof formData.roles === 'string') {
        formData.roles = [formData.roles]
      }

      if (formData.nonAdminRoles) {
        formData.roles = [
          ...formData.nonAdminRoles,
          ...formData.roles.filter((role) => isAdminRole(role))
        ]
        delete formData.nonAdminRoles
      }

      loadingButton.value = true
      try {
        await updateAdminUser(formData)
        modalEnabled.value = false
        resetUsers()
        fetchUsers()
        toast('Administrador editado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover este administrador ele perderá todos os acessos ao admin',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteUser = async (item) => {
      try {
        loadingModal.value = true
        await deleteAdminUser(item.id)
        users.value = users.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeleteUser(item)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      modalEnabled.value = false
    }

    return {
      permissions,
      tableHeaders,
      users,
      loading,
      isLastPage,

      hasInputAllowed,
      searchValue,

      newUser,

      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      formSchema,

      handleFormClose,
      handleEdit,
      initialFormData,
      loadingButton,

      handleFormCreation,
      handleDelete,

      handleConfirm,
      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Administradores</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newUser()">
          <i class="bi bi-node-plus-fill"></i>
          Adicionar novo administrador
        </button>
      </div>
    </div>

    <div class="row g-2 mt-3">
      <form-input
        class="col form-input"
        label="Administrador"
        v-model="searchValue"
        id="search"
        name="search"
        type="search"
        icon="bi bi-search"
        placeholder="Nome do administrador"
        autocomplete="off"
      />
    </div>

    <div class="row pb-5">
      <div class="col-12 form-table">
        <app-table
          v-if="users.length > 0"
          :headers="tableHeaders"
          :items="users"
          @delete="handleDelete"
          @edit="handleEdit"
        />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div v-if="users.length === 0 && !loading" class="d-flex align-items-center placeholder">
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
