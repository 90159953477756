<script>
import { useForm } from 'vee-validate'
import { ref } from 'vue'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import appTitle from '@/components/shared/title.vue'
import { createUser, deleteUser, sendUserClientInvite } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appUsers',

  components: {
    appTitle,
    formInput,
    formButton
  },
  props: {
    users: {
      type: Object,
      default: () => {}
    },
    clientId: {
      type: Number,
      default: 0
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const nameValue = ref('')
    const emailValue = ref('')
    const loading = ref(false)

    const { handleSubmit } = useForm({
      validationSchema: yup.object({
        userName: yup.string().required(),
        email: yup.string().email().required()
      })
    })

    const onSubmit = handleSubmit(async () => {
      const body = {
        active: true,
        clientId: props.clientId,
        userName: nameValue.value.trim(),
        email: emailValue.value.trim()
      }

      try {
        loading.value = true
        const response = await createUser(body)
        resetUser()

        const updatedUsers = [...props.users, response.data]
        emit('users', updatedUsers)

        toast('Usuários adicionado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    })

    const handleInvite = async (item) => {
      try {
        await sendUserClientInvite(item.id)

        toast('Convite reenviado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDelete = async (item) => {
      try {
        await deleteUser(item.id)

        const updateUsers = [...props.users]
        const index = updateUsers.findIndex((user) => user.id === item.id)
        updateUsers.splice(index, 1)
        emit('users', updateUsers)

        toast('Usuários removido', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const resetUser = () => {
      nameValue.value = ''
      emailValue.value = ''
    }

    return {
      nameValue,
      emailValue,

      onSubmit,
      handleDelete,
      handleInvite,
      loading
    }
  }
}
</script>

<template>
  <div>
    <div class="row g-2">
      <app-title label=" USUÁRIOS" />

      <form-input
        className="col form-input"
        label="Nome"
        v-model="nameValue"
        id="userName"
        name="userName"
        type="text"
      />

      <form-input
        className="col form-input"
        label="E-mail"
        v-model="emailValue"
        id="email"
        name="email"
        type="text"
      />

      <form-button
        class="col-auto form-input"
        title="Adicionar"
        :loading="loading"
        @click="onSubmit"
      />
    </div>
    <div class="row g-2 mb-1">
      <div class="col-12 users">
        <div class="row g-0" v-for="item in users" :key="item.id">
          <p class="col-5 mb-0">
            {{ item.userName }}
          </p>
          <p class="col-5 mb-0">
            {{ item.email }}
          </p>
          <div v-if="!item.hasRegisteredPassword" class="col-1 p-1">
            <button
              class="action-button bi bi-send-check-fill"
              @click="handleInvite(item)"
            ></button>
          </div>
          <div :class="!item.hasRegisteredPassword ? 'col-1' : 'col-2'" class="p-1">
            <button class="action-button bi bi-trash-fill" @click="handleDelete(item)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.users {
  > div {
    padding: 10px;
    background-color: #00000026;
    margin-top: 5px;
    border-radius: 9px;

    p {
      font-size: 12px;
      padding-top: 5px;
    }

    button {
      &.action-button {
        background-color: #f2f5ff;
        border: none;
        border-radius: 6px;
        width: 100%;
        padding: 4px 10px;
        color: #000000;
        font-size: 12px;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
</style>
