<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'

import formModalBroker from '@/components/modalBroker/formModalBroker.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { fetchBrokers } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appBrokers',

  components: {
    appLoading,
    appTable,
    formModalBroker,
    appModal
  },

  setup() {
    useMeta({ title: 'Brokers' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const brokers = ref('')
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'name', label: 'Nome', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          { action: 'delete', label: 'Deletar', class: 'delete', emitEvent: 'delete' }
        ]
      }
    ]

    const fetchOperations = async () => {
      loading.value = true
      try {
        const response = await fetchBrokers()
        brokers.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetOperations = () => {
      brokers.value = ''
    }

    const updateOperationsWithFilters = () => {
      fetchOperations()
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    onMounted(() => {
      fetchOperations()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const newBroker = () => {
      title.value = 'Adicionar broker'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitBroker(formData)
      } else {
        handleUpdateBroker(formData)
      }
    }

    const handleSubmitBroker = async (formData) => {
      loadingButton.value = true

      try {
        await createBroker(formData)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleEdit = (item) => {
      title.value = 'Editar broker'
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleUpdateBroker = async (updatedForm) => {
      loadingButton.value = true

      try {
        await putUpdateBroker(updatedForm.id, updatedForm)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-broker',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Deseja mesmo apagar este broker, ação não poderá ser revertida.',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteBroker = async (item) => {
      try {
        loadingModal.value = true
        await deleteBroker(item.id)
        brokers.value = brokers.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-broker') {
        handleDeleteBroker(item)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      brokers,
      loading,

      newBroker,
      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      handleSubmitBroker,
      handleUpdateBroker,
      handleFormClose,
      initialFormData,
      loadingButton,

      handleEdit,
      handleDelete,

      handleFormCreation,
      handleConfirm,
      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-broker
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Brokers</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newBroker()">
          <i class="bi bi-node-plus-fill"></i>
          Novo Broker
        </button>
      </div>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12 form-table">
        <app-table
          v-if="brokers.length > 0"
          :headers="tableHeaders"
          :items="brokers"
          :initialFormData="initialFormData"
          @edit="handleEdit"
          @delete="handleDelete"
        />

        <div v-if="brokers.length === 0 && !loading" class="d-flex align-items-center placeholder">
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
