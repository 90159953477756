import { defineStore } from 'pinia'

export const useAuthToken = defineStore('authToken', {
  state() {
    return {
      name: null,
      email: null,
      institution: null,
      token: null,
      role: null,
      roles: null
    }
  },

  getters: {
    getName() {
      return this.name
    },
    getInstitution() {
      return this.institution
    },
    getToken() {
      return this.token
    },
    getRole() {
      return this.role
    },
    getRoles() {
      return this.roles
    }
  },

  actions: {
    resetState() {
      this.$reset()
    }
  },

  persist: true
})
