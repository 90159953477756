import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const clientToken = process.env.DATADOG_CLIENT_TOKEN
const service = `tb-admin-web-${process.env.VITE_APP_ENVIRONMENT}`

datadogRum.init({
  applicationId: 'e5305ec3-d30f-46e8-be66-82c2ff655bd7',
  clientToken: clientToken,
  site: 'datadoghq.com',
  service: service,
  env: process.env.VITE_APP_ENVIRONMENT,
  version: process.env.VITE_APP_VERSION,
  sessionSampleRate: 50,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  enableExperimentalFeatures: ['feature_flags'],
  allowedTracingUrls: [(url) => url.includes('transferbank.com.br')]
})

datadogLogs.init({
  clientToken: clientToken,
  site: 'datadoghq.com',
  service: service,
  env: process.env.VITE_APP_ENVIRONMENT,
  version: process.env.VITE_APP_VERSION,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sessionSampleRate: 100
})
