<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'

import formModalPromoCode from '@/components/modalPromoCode/formPromoCode.vue'
import infoModal from '@/components/shared/infoModal.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { fetchPromoCodes, createPromoCode, updatePromoCode, deletePromoCode } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appPromoCodes',

  components: {
    appLoading,
    appTable,
    infoModal,
    appModal,
    formModalPromoCode
  },

  setup() {
    useMeta({ title: 'Códigos promocionais' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const promoCodes = ref('')
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'code', label: 'Código', type: 'text' },
      { key: 'beginsAt', label: 'Início', type: 'date', format: 'dd/MM/yyyy' },
      { key: 'endsAt', label: 'Fim', type: 'date', format: 'dd/MM/yyyy' },
      { key: 'spreadPercentageValue', label: 'Spread %', type: 'text' },
      { key: 'taxPercentageValue', label: 'Tarifa %', type: 'text' },
      { key: 'description', label: 'Descrição', type: 'text' },
      { key: 'clientCount', label: 'Qtde', type: 'info' },
      { key: 'hasIndicateAndWin', label: 'I e G', type: 'check' },
      { key: 'hasGiftCard', label: 'Gift', type: 'check' },
      { key: 'clientType', label: 'Tipo', type: 'text' },
      { key: 'institution', label: 'Destino', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          { action: 'delete', label: 'Deletar', class: 'delete', emitEvent: 'delete' }
        ]
      }
    ]

    const fetchPromoCode = async () => {
      loading.value = true
      try {
        const response = await fetchPromoCodes()
        promoCodes.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetPromoCodes = () => {
      promoCodes.value = ''
    }

    onMounted(() => {
      fetchPromoCode()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const modalInfoEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const handleEdit = (item) => {
      title.value = `Editar códigos ${item.code}`
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleInfo = (item) => {
      title.value = `Info`
      initialFormData.value = { ...item }
      modalInfoEnabled.value = true
    }

    const newPromoCode = () => {
      title.value = 'Adicionar código de desconto'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitPromoCode(formData)
      } else {
        handleUpdatePromoCode(formData)
      }
    }

    const handleSubmitPromoCode = async (formData) => {
      loadingButton.value = true
      try {
        await createPromoCode(formData)
        modalEnabled.value = false
        resetPromoCodes()
        fetchPromoCode()
        toast('Códigos promocionais cadastrado', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdatePromoCode = async (updatedForm) => {
      loadingButton.value = true
      try {
        await updatePromoCode(updatedForm)
        resetPromoCodes()
        fetchPromoCode()
        modalEnabled.value = false
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-news',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Deseja mesmo apagar este código promocional, ação não poderá ser revertida.',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-news') {
        handleDeletePromoCode(item)
      }
    }

    const handleDeletePromoCode = async (item) => {
      try {
        loadingModal.value = true
        await deletePromoCode(item.id)
        promoCodes.value = promoCodes.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const loadingModal = ref(false)

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalInfoEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      promoCodes,
      loading,

      newPromoCode,

      modalFormIsShown,
      modalEnabled,
      modalInfoEnabled,
      modalConfig,
      title,

      handleFormClose,
      initialFormData,
      loadingButton,

      handleFormCreation,
      handleInfo,
      handleEdit,
      handleDelete,

      handleConfirm,
      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal-promo-code
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <info-modal
      v-if="modalInfoEnabled"
      class="col-12 form-table"
      @onClose="handleFormClose"
      :title="title"
    >
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in initialFormData.promotionalCodeClientDTO" :key="client.clientId">
            <td>{{ client.clientId }}</td>
            <td>{{ client.clientName }}</td>
          </tr>
        </tbody>
      </table>
    </info-modal>

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Códigos promocionais</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newPromoCode()">
          <i class="bi bi-node-plus-fill"></i>
          Novo Código de desconto
        </button>
      </div>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12 form-table">
        <app-table
          v-if="promoCodes.length > 0"
          :headers="tableHeaders"
          :items="promoCodes"
          :initialFormData="initialFormData"
          @info="handleInfo"
          @edit="handleEdit"
          @delete="handleDelete"
        />

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
