<script>
import { hasInputAllowed } from '@composables/utils/useWhitelabelService.js'
import { parseISO, format, formatISO } from 'date-fns'
import { useForm } from 'vee-validate'
import { onMounted, ref, provide, watch, computed, nextTick } from 'vue'
import { useMeta } from 'vue-meta'
import { toast } from 'vue3-toastify'
import * as yup from 'yup'

import formButton from '@/components/form/button.vue'
import formInput from '@/components/form/input.vue'
import formMoney from '@/components/form/money.vue'
import formSearch from '@/components/form/search.vue'
import formSelect from '@/components/form/select.vue'
import appBankList from '@/components/shared/bankList.vue'
import appDocusignList from '@/components/shared/docusignList.vue'
import appImportDocument from '@/components/shared/importDocument.vue'
import appListDocument from '@/components/shared/listDocument.vue'
import appTitle from '@/components/shared/title.vue'
import {
  configClientMoney,
  configCommercialMoney,
  configMoney,
  configIof,
  configIr
} from '@/composables/utils/moneyConfig'
import { useCurrencyOptions } from '@/composables/utils/useCurrencyOptions'
import {
  clientTypes,
  transactionTypes,
  liquidationOptions,
  operationNatureTypes,
  operationTypes,
  spreadTypes,
  emptyPlaceholder,
  posStatusOptions
} from '@/composables/utils/useGlobalConfig'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import { greatherThan } from '@/composables/utils/validationUtils'
import {
  fetchClients,
  fetchClientBank,
  fetchTedClientBank,
  uploadDocument,
  uploadDarfAdmin,
  uploadDarf,
  uploadExchangeContract,
  uploadSwift,
  uploadTed,
  createPosDocsSignature
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useCache } from '@/stores/cache.js'

export default {
  name: 'formModalPos',

  components: {
    appTitle,
    formSearch,
    formInput,
    formMoney,
    formSelect,
    formButton,
    appImportDocument,
    appListDocument,
    appBankList,
    appDocusignList
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialFormData: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    useMeta({ title: props.title })

    const contentActive = ref(false)
    const scroll = ref(false)
    const { setNoScroll } = useNoScroll()

    const loadingSubmitDocusign = ref(false)

    const cacheNatureType = useCache().getNatureType

    const clientNameValue = ref('')
    const clientApiResult = ref([])
    const clientId = ref('')
    const clientType = ref('')

    const exchangeRecipient = ref({})

    const natureTypeValue = ref('')
    const operationOptions = ref([])

    const operationType = ref('')
    const docusignExchangeContract = ref('')

    const operationNatureValue = ref('')
    const operationNatureOptions = ref([])

    const operationSubnatureValue = ref('')
    const operationSubnatureOptions = ref([])

    const invoiceNumberValue = ref('')
    const principalAmountValue = ref('')

    const clientTaxRSValue = ref('')
    const commercialQuoteValue = ref('')
    const clientQuoteValue = ref('')
    const spreadType = ref('')
    const spreadValue = ref('')
    const liquidationValue = ref('')
    const iofBaseValue = ref('')
    const irBaseValue = ref('')
    const vetValue = ref('')
    const finalPriceRSValue = ref('')

    const sellOffRealValue = ref('')

    const bankChangeValue = ref('')

    const financialInstitutionValue = ref('')
    const financialInstitutionOptions = ref([])
    const payToValue = ref({})

    const clientQuoteUSDValue = ref('')

    const siscomexProtocolValue = ref('')
    const numberDIOrDUEValue = ref('')

    const statusValue = ref('')

    const tedBankValue = ref([])
    const tedFinancialInstitutionValue = ref('')
    const tedFinancialInstitutionOptions = ref([])

    const editModeEnabled = ref(false)
    const closeDateValue = ref('')
    const createAtDateValue = ref('')
    const operationDetail = ref({})
    const tedClientDetail = ref({})
    const beneficiaryAbroadDetail = ref({})
    const payerNameValue = ref('')
    const liquidationFCValue = ref('')
    const isLegacyOperation = ref(false)

    const documents = ref([])
    const documentsUpdated = ref(false)

    const typeFile = ref([
      { key: 'documents', name: 'DOCUMENTOS DA OPERAÇÃO' },
      { key: 'exchangeContract', name: 'CONTRATO DOCUSIGN' },
      { key: 'adminDarf', name: 'DARFS DA OPERAÇÃO' },
      { key: 'fileDIOrDUE', name: 'DI/DU-E' },
      { key: 'clientTeds', name: 'TED ENVIADA PELO CLIENTE' },
      { key: 'swiftTeds', name: 'SWIFT CLIENTE' },
      { key: 'fileDarf', name: 'DARF' }
    ])

    const loadingUpload = ref(false)
    const uploadedFileUrls = ref([])
    const uploadedDocusignFileUrls = ref([])

    const currencyTypeValue = ref('')
    const { fetchCurrencyOptions } = useCurrencyOptions()
    const currencyOptions = fetchCurrencyOptions()

    const bankOptions = ref([])
    const discountOnTheNextOperation = ref([])

    const documentNameValue = ref('')
    const emailSubjectValue = ref('')
    const signerEmailValue = ref('')
    const signerNameValue = ref('')
    const idContractValue = ref('')

    watch(scroll, (newValue) => {
      setNoScroll(newValue)
    })

    const close = () => {
      contentActive.value = false
      scroll.value = false
      setTimeout(() => {
        emit('onClose', false)
      }, 300)
    }

    const openModal = () => {
      scroll.value = true
      setTimeout(() => {
        contentActive.value = true
      }, 100)
    }

    onMounted(() => {
      openModal()
    })

    useSearchThrottle(clientNameValue, () => {
      const config = {
        search: clientNameValue.value
      }
      searchClientByName(config)
    })

    const searchClientByName = async (searchTerm) => {
      try {
        const response = await fetchClients(searchTerm)
        clientApiResult.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const selectedClient = async (data) => {
      clientNameValue.value = data.clientName
      clientId.value = data.clientId
      clientType.value = data.clientType
      spreadType.value = data.spreadType
      operationType.value = ''
      natureTypeValue.value = ''
      operationOptions.value = []
      operationNatureValue.value = ''
      operationNatureOptions.value = []
      operationSubnatureValue.value = ''
      operationSubnatureOptions.value = []
      tedBankValue.value = data.banks || []

      financialInstitutionOptions.value = (data.allowedFinancialInstitutions || [])
        .filter((item) => item.allowed)
        .map((item) => ({
          ...item,
          key: item.institution,
          name: item.institution
        }))

      fetchOperationOptions()
    }

    const fetchOperationOptions = async () => {
      const filteredData = cacheNatureType.filter((item) => item.clientType === clientType.value)
      operationOptions.value = filteredData.map((item) => ({
        ...item,
        key: item.subType,
        name: formatOptionName(item)
      }))
    }

    watch(natureTypeValue, (newVal) => {
      const selectedOperation = operationOptions.value.find((item) => item.key === newVal)

      if (selectedOperation && selectedOperation.natures.length > 0) {
        operationNatureOptions.value = selectedOperation.natures.map((nature) => ({
          ...nature,
          key: nature.code,
          name: nature.name
        }))
      } else {
        operationNatureOptions.value = []
      }

      operationType.value = selectedOperation.type

      if (operationType.value === operationTypes.SELLING) {
        tedFinancialInstitutionOptions.value = (tedBankValue.value || []).map((item) => ({
          ...item,
          key: item.account,
          name: item.name
        }))
      } else {
        tedFinancialInstitutionOptions.value = []
      }

      bankOptions.value = []
      if (operationType.value === operationTypes.BUYING) fetchClientBanks()
    })

    watch(financialInstitutionValue, async (newVal) => {
      if (operationType.value === operationTypes.BUYING) {
        try {
          const response = await fetchTedClientBank(newVal)
          tedFinancialInstitutionOptions.value = (response.data.payload.natBankAccounts || []).map(
            (item) => ({
              ...item,
              key: item.code,
              name: item.name
            })
          )
          if (tedFinancialInstitutionOptions.value.length === 1) {
            tedFinancialInstitutionValue.value = tedFinancialInstitutionOptions.value[0].key
            await nextTick()
          }
        } catch (e) {
          handleGlobalError(e)
        }
      }
    })

    const fetchClientBanks = async () => {
      try {
        const response = await fetchClientBank(clientId.value)
        bankOptions.value = (response.data.payload || []).map((item) => ({
          ...item,
          key: item.id,
          name: item.name
        }))
      } catch (e) {
        handleGlobalError(e)
      }
    }

    watch(operationNatureValue, (newVal) => {
      const selectedNature = operationNatureOptions.value.find((item) => item.key === newVal)
      if (selectedNature && selectedNature.subNatures.length > 0) {
        operationSubnatureOptions.value = selectedNature.subNatures.map((subNature) => ({
          ...subNature,
          key: subNature.code,
          name: subNature.name
        }))
      } else {
        operationSubnatureOptions.value = []
      }
    })

    watch(tedFinancialInstitutionValue, (newVal) => {
      const foundItem = tedFinancialInstitutionOptions.value.find((item) => item.key === newVal)

      if (foundItem != null) {
        payToValue.value = {
          name: foundItem.name,
          agency: foundItem.agency,
          account: foundItem.account,
          company: foundItem.company,
          cnpj: foundItem.cnpj,
          pix: foundItem.pix,
          code: foundItem.code,
          defaultAccount: foundItem.defaultAccount
        }
      }
    })

    const formatOptionName = (item) => {
      if (item.clientType === clientTypes.PF) {
        if (
          item.subType === transactionTypes.TRANSFERBUY ||
          item.subType === transactionTypes.TRADEBUY
        ) {
          return `${item.name} - Envio`
        } else if (
          item.subType === transactionTypes.TRADESELL ||
          item.subType === transactionTypes.TRANSFERSELL
        ) {
          return `${item.name} - Recebimento`
        }
      }
      return item.name
    }

    const fileBinaries = ref(new Map())

    const handleFileSelected = async (files) => {
      for (const file of files) {
        loadingUpload.value = true

        const fileInfo = {
          name: file.name,
          url: '',
          fileCode: ''
        }

        fileBinaries.value.set(file.name, file)
        uploadedFileUrls.value.push(fileInfo)
        loadingUpload.value = false
      }
    }

    const groupDocumentsByFileCode = (documents) => {
      const grouped = {}
      documents.forEach((doc) => {
        if (!grouped[doc.fileCode]) {
          grouped[doc.fileCode] = []
        }
        grouped[doc.fileCode].push(doc)
      })
      return grouped
    }

    const handleDocumentUpdate = (updatedDocuments) => {
      documents.value = groupDocumentsByFileCode(updatedDocuments)
      documentsUpdated.value = true
    }

    const removeFile = (index) => {
      if (index >= 0 && index < uploadedFileUrls.value.length) {
        uploadedFileUrls.value.splice(index, 1)
      }
    }

    watch(bankChangeValue, (newVal) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === newVal)
    })

    const updateBank = async (id) => {
      exchangeRecipient.value = bankOptions.value.find((item) => item.key === id)
    }

    const formatOperationType = (value) => {
      return value ? (value == operationTypes.SELLING ? 'VENDA' : 'COMPRA') : ''
    }

    const formatNumber = (value, locale, decimal) => {
      if (value <= 0) {
        return ''
      }
      const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: decimal || 2,
        maximumFractionDigits: decimal || 2
      })

      return formatter.format(value)
    }

    const formatDoc = (type, operation) => {
      const item = operation[type]

      if (Array.isArray(item)) {
        return item.map((doc) => ({
          name: doc.name,
          url: doc.url,
          fileCode: type
        }))
      } else if (item && typeof item === 'object') {
        return [
          {
            name: item.name,
            url: item.url,
            fileCode: type
          }
        ]
      } else {
        return []
      }
    }

    watch(
      () => props.initialFormData,
      async (newFormData) => {
        if (Object.keys(newFormData).length === 0) return
        editModeEnabled.value = true
        if (newFormData != null) {
          clientType.value = newFormData.clientType

          fetchOperationOptions()
          natureTypeValue.value = newFormData.operation.subType

          clientNameValue.value = newFormData.clientName
          clientId.value = newFormData.clientId
          financialInstitutionValue.value = newFormData.financialInstitution
          operationNatureValue.value = newFormData.operation.operationNature || ''
          operationSubnatureValue.value = newFormData.operation.operationSubNature || ''

          signerNameValue.value = newFormData.userDefaultSignerName
            ? newFormData.userDefaultSignerName
            : newFormData.mainUserName
          signerEmailValue.value = newFormData.userDefaultSignerEmail
            ? newFormData.userDefaultSignerEmail
            : newFormData.mainUserEmail

          const date = parseISO(newFormData.createdAt)
          closeDateValue.value = format(date, 'dd/MM/yyyy HH:mm')

          const createdAt = parseISO(newFormData.createdAt)
          createAtDateValue.value = format(createdAt, 'dd/MM/yyyy HH:mm')

          if (newFormData.type === operationTypes.SELLING) {
            const openOption = posStatusOptions.value.find((option) => option.key === 'OPEN')
            if (openOption) {
              openOption.name = 'Em liquidação'
            }
            payerNameValue.value = newFormData.operation.payerName
          } else if (newFormData.type === operationTypes.BUYING) {
            beneficiaryAbroadDetail.value = newFormData.operation.exchangeRecipient
          }

          tedClientDetail.value = newFormData.payTo
          spreadType.value = newFormData.spreadType

          operationDetail.value = {
            id: newFormData.id,
            principalAmount: newFormData.principalAmount,
            commercialQuote: newFormData.commercialQuote,
            clientQuote: newFormData.clientQuote,
            spread:
              (newFormData.spreadType == spreadTypes.PERCENTAGE ? '% ' : 'R$ ') +
              newFormData.spread,
            liquidation: newFormData.liquidation,
            clientTaxRS: newFormData.clientTaxRS,
            iofValue: newFormData.operation.iofValue,
            irValue: newFormData.operation.irValue,
            vet: newFormData.vet,
            finalPriceRS: newFormData.operation.finalPriceRS
          }

          discountOnTheNextOperation.value = newFormData.discountOnTheNextOperation || []
          currencyTypeValue.value = newFormData.currencyType
          principalAmountValue.value = newFormData.principalAmount
          invoiceNumberValue.value = newFormData.operation.invoiceNumber
          commercialQuoteValue.value = newFormData.commercialQuote
          clientQuoteValue.value = newFormData.clientQuote
          spreadValue.value = newFormData.spread
          liquidationFCValue.value = newFormData.operation.liquidationFC
          liquidationValue.value = newFormData.liquidation
          irBaseValue.value = newFormData.ir * 100
          iofBaseValue.value = newFormData.iof * 100
          clientTaxRSValue.value = newFormData.clientTaxRS

          clientQuoteUSDValue.value = newFormData.clientQuoteUSD
          numberDIOrDUEValue.value = newFormData.operation.numberDIOrDUE
          siscomexProtocolValue.value = newFormData.operation.siscomexProtocol

          vetValue.value = newFormData.vet
          finalPriceRSValue.value = newFormData.finalPriceRS
          statusValue.value = newFormData.status

          isLegacyOperation.value = newFormData.isLegacyOperation

          docusignExchangeContract.value = newFormData.docusignExchangeContract

          const docs = formatDoc('documents', newFormData.operation) || []

          const docsfileDIOrDUE = formatDoc('fileDIOrDUE', newFormData) || []
          const docsAdminDarf = formatDoc('adminDarf', newFormData) || []
          const docseExchangeContract = formatDoc('exchangeContract', newFormData) || []
          const clientTeds = formatDoc('clientTeds', newFormData) || []
          const swiftTeds = formatDoc('swiftTeds', newFormData) || []
          const fileDarf = formatDoc('fileDarf', newFormData) || []

          uploadedFileUrls.value.push(
            ...docs,
            ...docsfileDIOrDUE,
            ...docsAdminDarf,
            ...docseExchangeContract,
            ...clientTeds,
            ...swiftTeds,
            ...fileDarf
          )
        }
      },
      { immediate: true }
    )

    let validationSchema = yup.object({})
    let operationSubnatureValidation =
      operationSubnatureOptions.value.length > 0 ? yup.string().required() : yup.string()

    if (editModeEnabled.value !== true) {
      validationSchema = validationSchema.shape({
        search: yup.string().required(),
        natureType: yup.string().required(),
        operationNature: yup.string().required(),

        operationSubnature: operationSubnatureValidation,
        currencyType: yup.string().required(),

        principalAmount: greatherThan(),
        commercialQuote: greatherThan(),
        clientQuote: greatherThan(),
        spread: greatherThan(),

        vet: greatherThan(),
        finalPriceRS: greatherThan()
      })
    } else {
      validationSchema = validationSchema.shape({
        commercialQuote: greatherThan(),
        principalAmount: greatherThan(),
        finalPriceRS: greatherThan(),
        vet: yup.string().required(),
        status: yup.string().required()
      })
    }

    const { handleSubmit } = useForm({
      validationSchema: validationSchema
    })

    const alreadySubmittedOnce = ref(false)
    provide('alreadySubmittedOnce', alreadySubmittedOnce)

    const onSubmitAction = () => {
      alreadySubmittedOnce.value = true
      return onSubmit()
    }

    const uploadFilesToS3 = async (files, uploadApiFunction) => {
      const uploadPromises = files.map(async (fileObj) => {
        const fileBinary = fileBinaries.value.get(fileObj.name)

        if (fileBinary) {
          const formData = new FormData()
          formData.append('file', fileBinary)

          try {
            const response = await uploadApiFunction(formData)
            return {
              ...fileObj,
              url: response.data.payload.url
            }
          } catch (e) {
            handleGlobalError(e)
            throw e
          }
        } else {
          throw new Error(`Arquivo binário não encontrado: ${fileObj.name}`)
        }
      })
      return Promise.all(uploadPromises)
    }

    const handleUpload = async (fileType, uploadApiFunction) => {
      if (documents.value[fileType] === undefined || documents.value[fileType].length === 0) {
        console.debug(`Nenhum arquivo para upload em ${fileType}`)
        return
      }

      const filesToUpload = documents.value[fileType].filter(
        (file) => file.fileCode === fileType && !file.url
      )

      if (filesToUpload.length === 0) {
        console.debug(`Nenhum arquivo novo para upload em ${fileType}`)
        return
      }

      try {
        const updatedFiles = await uploadFilesToS3(filesToUpload, uploadApiFunction)

        updatedFiles.forEach((updatedFile) => {
          const index = documents.value[fileType].findIndex(
            (file) => file.name === updatedFile.name
          )
          if (index !== -1) {
            documents.value[fileType][index] = updatedFile
          }
        })
        console.debug(`Upload concluído para ${fileType}`)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const onSubmit = handleSubmit(async () => {
      if (editModeEnabled.value !== true) {
        const body = {
          clientName: clientNameValue.value,
          clientId: clientId.value.toString(),
          clientType: clientType.value,
          currencyType: currencyTypeValue.value,
          principalAmount: parseFloat(principalAmountValue.value),
          commercialQuote: parseFloat(commercialQuoteValue.value),
          clientQuote: parseFloat(clientQuoteValue.value),
          spread: parseFloat(spreadValue.value),
          clientTaxRS: parseFloat(clientTaxRSValue.value),
          liquidation: parseFloat(liquidationValue.value),

          iof: parseFloat(iofBaseValue.value / 100),
          ir: parseFloat(irBaseValue.value / 100),

          vet: parseFloat(vetValue.value),
          finalPriceRS: parseFloat(finalPriceRSValue.value),
          financialInstitution: financialInstitutionValue.value,
          payTo: payToValue.value,
          spreadType: 'PERCENTAGE',
          status: 'OPEN',
          operationType: operationType.value,
          clientQuoteUSD: clientQuoteUSDValue.value,

          operation: {
            numberDIOrDUE: numberDIOrDUEValue.value,
            invoiceNumber: invoiceNumberValue.value,
            siscomexProtocol: siscomexProtocolValue.value,

            operationNature: operationNatureValue.value,
            documents: [],
            liquidationFC: liquidationFCValue.value,
            operationSubnature: operationSubnatureValue.value,
            subType: natureTypeValue.value,
            exchangeRecipient: exchangeRecipient.value
          }
        }
        emit('onSubmitted', body)
      } else {
        await handleUpload('documents', uploadDocument)
        await handleUpload('adminDarf', uploadDarfAdmin)
        await handleUpload('fileDIOrDUE', uploadDocument)
        await handleUpload('exchangeContract', uploadExchangeContract)
        await handleUpload('swiftTeds', uploadSwift)
        await handleUpload('clientTeds', uploadTed)
        await handleUpload('fileDarf', uploadDarf)

        const body = {
          ...props.initialFormData,
          principalAmount: parseFloat(principalAmountValue.value),
          operation: {
            ...props.initialFormData.operation,
            invoiceNumber: invoiceNumberValue.value,
            finalPriceRS: parseFloat(finalPriceRSValue.value),
            principalAmount: parseFloat(principalAmountValue.value),
            siscomexProtocol: siscomexProtocolValue.value,
            ...(documentsUpdated.value && {
              documents: documents.value?.documents || undefined
            })
          },

          ...(documentsUpdated.value && {
            clientTeds: documents.value?.clientTeds || undefined,
            swiftTeds: documents.value?.swiftTeds || undefined,
            fileDIOrDUE:
              Array.isArray(documents.value.fileDIOrDUE) && documents.value.fileDIOrDUE.length > 0
                ? documents.value.fileDIOrDUE[0]
                : undefined,
            adminDarf:
              Array.isArray(documents.value.adminDarf) && documents.value.adminDarf.length > 0
                ? documents.value.adminDarf[0]
                : undefined,
            exchangeContract:
              Array.isArray(documents.value.exchangeContract) &&
              documents.value.exchangeContract.length > 0
                ? documents.value.exchangeContract[0]
                : undefined,
            fileDarf:
              Array.isArray(documents.value.fileDarf) && documents.value.fileDarf.length > 0
                ? documents.value.fileDarf[0]
                : undefined
          }),

          docusignExchangeContract: docusignExchangeContract.value,
          ir: parseFloat(irBaseValue.value / 100),
          iof: parseFloat(iofBaseValue.value / 100),
          commercialQuote: parseFloat(commercialQuoteValue.value),
          clientQuote: parseFloat(clientQuoteValue.value),
          spread: parseFloat(spreadValue.value),
          clientTaxRS: parseFloat(clientTaxRSValue.value),
          vet: parseFloat(vetValue.value),
          finalPriceRS: parseFloat(finalPriceRSValue.value),
          status: statusValue.value
        }

        emit('onSubmitted', body)
      }
    })

    const numberDIOrDUELabel = computed(() => {
      return operationNatureValue.value === operationNatureTypes.EXPMERC
        ? 'Número DU-E'
        : 'Número DI'
    })

    const tedFinancialInstitutionLabel = computed(() => {
      return (
        'TED para transferência ' +
        (natureTypeValue.value === transactionTypes.TRADESELL ? 'venda' : 'compra')
      )
    })

    const copyOperation = () => {
      const fmtBRL = (value, decimal = null) => {
        return value ? 'R$ ' + formatNumber(value, 'pt-BR', decimal) : ''
      }

      const vOrD = (value) => value || emptyPlaceholder.value

      const items = [
        {
          title: `Cliente ${formatOperationType(operationType)}`,
          value: `${currencyTypeValue.value} ${fmtBRL(principalAmountValue.value)}`
        },
        {
          title: `${currencyTypeValue.value} comercial`,
          value: vOrD(fmtBRL(operationDetail.value.commercialQuote, 4))
        },
        {
          title: 'Cotação',
          value: vOrD(fmtBRL(operationDetail.value.clientQuote, 4))
        },
        {
          title: 'Spread',
          value: vOrD(operationDetail.value.spread)
        },
        {
          title: 'Liquidação',
          value: vOrD(operationDetail.value.liquidation)
        },
        {
          title: 'Tarifa total',
          value: vOrD(fmtBRL(operationDetail.value.clientTaxRS))
        },
        {
          title: 'IOF',
          value: vOrD(fmtBRL(operationDetail.value.iofValue))
        },
        {
          title: 'IR',
          value: vOrD(fmtBRL(operationDetail.value.irValue))
        },
        {
          title: 'VET dólar',
          value: vOrD(fmtBRL(operationDetail.value.vet, 4))
        },
        {
          title: 'VET operação',
          value: vOrD(fmtBRL(operationDetail.value.finalPriceRS))
        }
      ]

      const formattedText = items.reduce((acc, item) => `${acc}${item.title}\t${item.value}\n`, '')

      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          toast('Operação copiada!', {
            theme: 'auto',
            type: 'success',
            dangerouslyHTMLString: true
          })
        })
        .catch((e) => handleGlobalError(e))
    }

    const handleUploadDocusign = async (fileType, uploadApiFunction) => {
      if (!uploadedDocusignFileUrls.value || uploadedDocusignFileUrls.value.length === 0) {
        console.debug(`Nenhum arquivo para upload em ${fileType}`)
        return
      }

      const filesToUpload = uploadedDocusignFileUrls.value.filter(
        (file) => file.fileCode === fileType && !file.url
      )

      if (filesToUpload.length === 0) {
        console.debug(`Nenhum arquivo novo para upload em ${fileType}`)
        return
      }

      try {
        const updatedFiles = await uploadFilesToS3(filesToUpload, uploadApiFunction)

        updatedFiles.forEach((updatedFile) => {
          const index = uploadedDocusignFileUrls.value.findIndex(
            (file) => file.name === updatedFile.name
          )
          if (index !== -1) {
            uploadedDocusignFileUrls.value[index] = updatedFile
          }
        })

        console.debug(`Upload concluído para ${fileType}`)
      } catch (e) {
        handleGlobalError(e)
      }
    }

    const handleDocusign = async (files) => {
      for (const file of files) {
        loadingUpload.value = true

        const fileInfo = {
          name: file.name,
          url: '',
          fileCode: 'docusign'
        }

        fileBinaries.value.set(file.name, file)
        uploadedDocusignFileUrls.value.push(fileInfo)
        loadingUpload.value = false
      }
    }

    const onSubmitDocusign = async () => {
      try {
        loadingSubmitDocusign.value = true
        await handleUploadDocusign('docusign', uploadDocument)
        const contract = uploadedDocusignFileUrls.value[0].url || undefined

        const body = {
          fileUrl: contract,
          documentName: documentNameValue.value,
          emailSubject: emailSubjectValue.value,
          signerEmail: signerEmailValue.value,
          signerName: signerNameValue.value,
          bs2ExchangeContractId: idContractValue.value,
          metadata: {
            financialInstitution: financialInstitutionValue.value,
            date: formatISO(new Date())
          }
        }
        const response = await createPosDocsSignature(body, props.initialFormData.id)
        toast('Documento enviado para assinatura', {
          theme: 'auto',
          type: 'success',
          dangerouslyHTMLString: true
        })

        docusignExchangeContract.value = response.data.payload
        uploadedDocusignFileUrls.value = []
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingSubmitDocusign.value = false
      }
    }

    return {
      clientNameValue,
      clientType,
      clientApiResult,
      clientId,
      operationType,
      natureTypeValue,
      operationOptions,
      operationNatureValue,
      operationNatureOptions,
      operationSubnatureValue,
      operationSubnatureOptions,
      closeDateValue,
      createAtDateValue,
      invoiceNumberValue,

      clientTaxRSValue,
      commercialQuoteValue,
      clientQuoteValue,
      spreadType,
      spreadValue,
      liquidationValue,
      iofBaseValue,
      vetValue,
      finalPriceRSValue,

      principalAmountValue,
      configMoney,
      configClientMoney,
      configCommercialMoney,
      configIof,
      irBaseValue,
      configIr,
      liquidationOptions,
      sellOffRealValue,

      currencyTypeValue,
      currencyOptions,

      financialInstitutionValue,
      financialInstitutionOptions,

      statusValue,
      posStatusOptions,

      close,
      onSubmitAction,
      contentActive,

      handleFileSelected,
      handleDocusign,
      uploadedDocusignFileUrls,
      handleDocumentUpdate,
      uploadedFileUrls,
      loadingUpload,
      removeFile,
      typeFile,

      bankChangeValue,
      bankOptions,
      exchangeRecipient,
      updateBank,

      tedFinancialInstitutionValue,
      tedFinancialInstitutionOptions,

      selectedClient,

      clientQuoteUSDValue,
      siscomexProtocolValue,
      numberDIOrDUEValue,

      editModeEnabled,
      formatNumber,
      formatOperationType,
      operationDetail,
      tedClientDetail,
      beneficiaryAbroadDetail,
      payerNameValue,
      liquidationFCValue,
      discountOnTheNextOperation,
      operationTypes,
      numberDIOrDUELabel,
      tedFinancialInstitutionLabel,

      spreadTypes,
      copyOperation,
      emptyPlaceholder,
      hasInputAllowed,
      isLegacyOperation,
      transactionTypes,

      loadingSubmitDocusign,
      documentNameValue,
      emailSubjectValue,
      signerEmailValue,
      signerNameValue,
      idContractValue,
      onSubmitDocusign,

      docusignExchangeContract
    }
  }
}
</script>

<template>
  <div class="box-content d-flex justify-content-end">
    <div :class="['back', { active: contentActive }]" @click="close()"></div>
    <div :class="['content', { active: contentActive }]">
      <div class="row">
        <h4 class="col-8">{{ title }}</h4>
        <div v-if="editModeEnabled" class="col-4 text-end">
          <h5>
            <span :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
              <b>{{ formatOperationType(operationType) }}</b>
            </span>
            {{ currencyTypeValue }} {{ formatNumber(principalAmountValue, 'pt-BR') }}
          </h5>
          <p>{{ createAtDateValue }}</p>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-12 form-input">
            <div v-if="isLegacyOperation" class="alert alert-warning" role="alert">
              <i class="bi bi-exclamation-triangle-fill"></i>
              Esta é uma operação legada, e pode não apresentar todas as informações na integra.
              Consulte a equipe de desenvolvimento para mais informações.
            </div>
          </div>

          <form-input
            v-if="editModeEnabled"
            className="col-12 form-input"
            v-model="clientId"
            :label="clientType === 'PJ' ? 'CNPJ' : 'CPF'"
            id="clientId"
            name="clientId"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-search
            className="col-12 form-input"
            label="Cliente"
            v-model="clientNameValue"
            id="search"
            name="search"
            type="search"
            icon="bi bi-search"
            placeholder="Nome do cliente"
            :results="clientApiResult"
            @onSelect="selectedClient"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-input
            v-if="editModeEnabled"
            className="col-12 form-input"
            v-model="financialInstitutionValue"
            label="Instituição financeira"
            id="financialInstitutionValue"
            name="financialInstitutionValue"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <form-select
            class="col-12 form-input"
            label="Tipo de operação"
            v-model="natureTypeValue"
            :items="operationOptions"
            id="natureType"
            name="natureType"
            :isDisabled="!hasInputAllowed() || operationOptions.length === 0 || editModeEnabled"
          />

          <form-select
            class="col-12 form-input"
            label="Natureza"
            v-model="operationNatureValue"
            :items="operationNatureOptions"
            id="operationNature"
            name="operationNature"
            :isDisabled="
              !hasInputAllowed() || operationNatureOptions.length === 0 || editModeEnabled
            "
          />

          <form-select
            v-if="operationSubnatureOptions.length > 0"
            class="col-12 form-input"
            label="Sub natureza"
            v-model="operationSubnatureValue"
            :items="operationSubnatureOptions"
            id="operationSubnature"
            name="operationSubnature"
            :isDisabled="
              !hasInputAllowed() || operationSubnatureOptions.length === 0 || editModeEnabled
            "
          />

          <form-input
            v-if="editModeEnabled"
            className="col-12 form-input"
            v-model="closeDateValue"
            label="Data de fechamento"
            id="closeDate"
            name="closeDate"
            type="text"
            :isDisabled="!hasInputAllowed() || editModeEnabled"
          />

          <div v-if="editModeEnabled" class="col-12 mt-4">
            <div class="operation">
              <div class="row">
                <i class="bi bi-copy" @click="copyOperation"></i>

                <div class="col-6">
                  <label>Operação:</label>
                  <p>{{ operationDetail.id }}</p>
                </div>
                <div class="col-6">
                  <label>Cliente {{ formatOperationType(operationType) }}:</label>
                  <p>{{ currencyTypeValue }} {{ formatNumber(principalAmountValue, 'pt-BR') }}</p>
                </div>

                <div class="col-6">
                  <label>{{ currencyTypeValue }} comercial:</label>
                  <p>R$ {{ formatNumber(operationDetail.commercialQuote, 'pt-BR', 4) }}</p>
                </div>
                <div class="col-6">
                  <label>Cotação:</label>
                  <p>R$ {{ formatNumber(operationDetail.clientQuote, 'pt-BR', 4) }}</p>
                </div>

                <div class="col-6">
                  <label>Spread:</label>
                  <p>{{ operationDetail.spread }}</p>
                </div>
                <div class="col-6">
                  <label>Liquidação:</label>
                  <p>R$ {{ operationDetail.liquidation || emptyPlaceholder }}</p>
                </div>

                <div class="col-6">
                  <label>Tarifa total:</label>
                  <p>
                    R$ {{ formatNumber(operationDetail.clientTaxRS, 'pt-BR') || emptyPlaceholder }}
                  </p>
                </div>
                <div class="col-6">
                  <label>IOF:</label>
                  <p>
                    R$ {{ formatNumber(operationDetail.iofValue, 'pt-BR') || emptyPlaceholder }}
                  </p>
                </div>

                <div class="col-6">
                  <label>IR:</label>
                  <p>R$ {{ formatNumber(operationDetail.irValue, 'pt-BR') || emptyPlaceholder }}</p>
                </div>

                <div class="col-6">
                  <label>Invoice:</label>
                  <p>{{ invoiceNumberValue || emptyPlaceholder }}</p>
                </div>

                <div class="col-6">
                  <label>VET dólar:</label>
                  <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
                    R$ {{ formatNumber(operationDetail.vet, 'pt-BR', 4) }}
                  </p>
                </div>
                <div class="col-6">
                  <label>VET operação:</label>
                  <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
                    R$ {{ formatNumber(operationDetail.finalPriceRS, 'pt-BR') }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <app-title
            v-if="editModeEnabled && discountOnTheNextOperation.length > 0"
            label="DESCONTOS DESTA OPERAÇÃO:"
          />

          <div v-if="editModeEnabled && discountOnTheNextOperation.length > 0" class="col-12">
            <table class="discount-detail">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CNPJ</th>
                  <th>Código Promocional</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in discountOnTheNextOperation" :key="item.promotionalCode">
                  <td>{{ item.name }}</td>
                  <td>{{ item.cnpj }}</td>
                  <td>{{ item.promotionalCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <app-title
            v-if="editModeEnabled && operationType === operationTypes.BUYING"
            label="TED CLIENTE"
          />

          <div v-if="editModeEnabled && operationType === operationTypes.BUYING" class="col-12">
            <div class="operation">
              <div class="row">
                <div class="col-6">
                  <label>Nome:</label>
                  <p>{{ tedClientDetail?.name || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>Banco:</label>
                  <p>{{ tedClientDetail?.company || emptyPlaceholder }}</p>
                </div>

                <div class="col-6">
                  <label>Agência:</label>
                  <p>{{ tedClientDetail?.agency || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>Conta:</label>
                  <p>{{ tedClientDetail?.account || emptyPlaceholder }}</p>
                </div>

                <div class="col-12">
                  <label>PIX:</label>
                  <p>{{ tedClientDetail?.pix || emptyPlaceholder }}</p>
                </div>
              </div>
            </div>
          </div>

          <app-title
            v-if="editModeEnabled && operationType === operationTypes.BUYING"
            label="TRANSFERÊNCIA PARA CONTA DO BENEFICIÁRIO NO EXTERIOR"
          />

          <div v-if="editModeEnabled && operationType === operationTypes.BUYING" class="col-12">
            <div class="operation">
              <div class="row">
                <div class="col-12">
                  <label>Nome de quem irá receber:</label>
                  <p>{{ beneficiaryAbroadDetail.name }}</p>
                </div>
                <div class="col-6">
                  <label>País:</label>
                  <p>{{ beneficiaryAbroadDetail.country }}</p>
                </div>
                <div class="col-6">
                  <label>Código SWIFT/BIC:</label>
                  <p>{{ beneficiaryAbroadDetail.swift }}</p>
                </div>
                <div class="col-6">
                  <label>Banco:</label>
                  <p>{{ beneficiaryAbroadDetail.bank }}</p>
                </div>
                <div class="col-6">
                  <label>Conta:</label>
                  <p>{{ beneficiaryAbroadDetail.account }}</p>
                </div>

                <div class="col-6">
                  <label>Liquidação em:</label>
                  <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
                    ME: {{ liquidationFCValue }}
                  </p>
                </div>

                <div
                  v-if="beneficiaryAbroadDetail?.intermediaryBankAccount?.name != null"
                  class="row"
                >
                  <div class="col-12">
                    <hr />
                  </div>

                  <div class="col-12">
                    <label>Banco intermediário:</label>
                    <p>
                      {{
                        beneficiaryAbroadDetail.intermediaryBankAccount?.name || emptyPlaceholder
                      }}
                    </p>
                  </div>

                  <div class="col-6">
                    <label>Conta:</label>
                    <p>
                      {{
                        beneficiaryAbroadDetail.intermediaryBankAccount?.account || emptyPlaceholder
                      }}
                    </p>
                  </div>

                  <div class="col-6">
                    <label>SWIFT:</label>
                    <p>
                      {{
                        beneficiaryAbroadDetail.intermediaryBankAccount?.swift || emptyPlaceholder
                      }}
                    </p>
                  </div>
                </div>

                <div v-if="beneficiaryAbroadDetail?.forFurtherCredit != null" class="row">
                  <div class="col-12">
                    <hr />
                  </div>
                  <div class="col-12">
                    <label>For Further Credit ou demais referências</label>
                    <p>
                      {{ beneficiaryAbroadDetail?.forFurtherCredit || emptyPlaceholder }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-title
            v-if="editModeEnabled && operationType === operationTypes.SELLING"
            label="TRANSFERÊNCIA PARA CONTA DO CLIENTE"
          />

          <div v-if="editModeEnabled && operationType === operationTypes.SELLING" class="col-12">
            <div class="operation">
              <div class="row">
                <div class="col-12">
                  <label>Banco:</label>
                  <p>{{ tedClientDetail?.name || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>Agência:</label>
                  <p>{{ tedClientDetail?.agency || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>Conta:</label>
                  <p>{{ tedClientDetail?.account || emptyPlaceholder }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label>Referente a:</label>
                  <p>{{ currencyTypeValue }} {{ formatNumber(principalAmountValue, 'pt-BR') }}</p>
                </div>
                <div class="col-6">
                  <label>Invoice:</label>
                  <p>{{ invoiceNumberValue || emptyPlaceholder }}</p>
                </div>

                <div class="col-6">
                  <label>Ordenante:</label>
                  <p>{{ payerNameValue || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>IOF:</label>
                  <p>
                    R$ {{ formatNumber(operationDetail.iofValue, 'pt-BR') || emptyPlaceholder }}
                  </p>
                </div>

                <div class="col-6">
                  <label>IR:</label>
                  <p>R$ {{ formatNumber(operationDetail.irValue, 'pt-BR') || emptyPlaceholder }}</p>
                </div>
                <div class="col-6">
                  <label>Tarifa:</label>
                  <p>{{ formatNumber(operationDetail.clientTaxRS, 'pt-BR') }}</p>
                </div>

                <div class="col-6">
                  <label>Transferir:</label>
                  <p :class="operationType === operationTypes.SELLING ? 'color-sell' : 'color-buy'">
                    R$ {{ formatNumber(operationDetail.finalPriceRS, 'pt-BR') }} em
                    {{ liquidationFCValue }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <app-title label="DETALHES DA OPERAÇÃO" />
          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Moeda selecionada"
            v-model="currencyTypeValue"
            :items="currencyOptions"
            id="currencyType"
            name="currencyType"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            v-if="currencyTypeValue && currencyTypeValue !== 'USD'"
            class="col-12 form-input"
            v-model="clientQuoteUSDValue"
            label="Cotação do dolar"
            id="clientQuoteUSD"
            name="clientQuoteUSD"
            :config="configClientMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="principalAmountValue"
            label="Cliente vendeu"
            id="principalAmount"
            name="principalAmount"
            :config="configClientMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            className="col-12 form-input"
            v-model="invoiceNumberValue"
            label="Invoice"
            id="invoiceNumber"
            name="invoiceNumber"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="commercialQuoteValue"
            label="Comercial"
            id="commercialQuote"
            name="commercialQuote"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="clientQuoteValue"
            label="Cotação"
            id="clientQuote"
            name="clientQuote"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="spreadValue"
            label="Spread"
            id="spread"
            name="spread"
            :config="spreadType == spreadTypes.PERCENTAGE ? configIr : configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="clientTaxRSValue"
            label="Tarifa"
            id="clientTaxRS"
            name="clientTaxRS"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Liquidação ME"
            v-model="liquidationFCValue"
            :items="liquidationOptions"
            id="liquidationFC"
            name="liquidationFC"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="liquidationValue"
            label="Valor liquidação"
            id="liquidation"
            name="liquidation"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="iofBaseValue"
            label="Base IOF"
            id="iofBase"
            name="iofBase"
            :config="configIof"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="irBaseValue"
            label="Base IR"
            id="irBase"
            name="irBase"
            :config="configIr"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="vetValue"
            label="Vet dólar"
            id="vet"
            name="vet"
            :config="configCommercialMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            class="col-12 form-input"
            v-model="finalPriceRSValue"
            label="Vet operação"
            id="finalPriceRS"
            name="finalPriceRS"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-money
            v-if="editModeEnabled && natureTypeValue === operationTypes.BUYING"
            class="col-12 form-input"
            v-model="principalAmountValue"
            label="Valor USD:"
            id="principalAmount"
            name="principalAmount"
            :config="configMoney"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="natureTypeValue === operationTypes.BUYING || natureTypeValue === 'TRADEBUY'"
            className="col-12 form-input"
            label="Protocolo SISCOMEX"
            v-model="siscomexProtocolValue"
            id="siscomexProtocol"
            name="siscomexProtocol"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <form-input
            v-if="
              natureTypeValue !== null &&
              operationNatureValue !== null &&
              (operationNatureValue === 'EXPMERC' || operationNatureValue === 'IMPORMERC')
            "
            className="col-12 form-input"
            :label="numberDIOrDUELabel"
            v-model="numberDIOrDUEValue"
            id="numberDIOrDUE"
            name="numberDIOrDUE"
            type="text"
            :isDisabled="!hasInputAllowed()"
          />

          <app-title v-if="editModeEnabled" label="DOCUMENTAÇÃO" />

          <app-import-document
            v-if="hasInputAllowed() && editModeEnabled"
            id="docs"
            name="docus"
            class="col-12"
            @fileSelected="handleFileSelected"
            accept="image/*, .pdf, .word"
            :loading="loadingUpload"
          />

          <app-list-document
            v-if="editModeEnabled"
            class="col-12"
            :uploadedFileUrls="uploadedFileUrls"
            :typeFile="typeFile"
            @update-documents="handleDocumentUpdate"
            @file-removed="removeFile"
          />

          <app-title
            v-if="editModeEnabled && principalAmountValue > 10000"
            label="CONTRATO DE CÂMBIO"
          />

          <app-docusign-list :contract="docusignExchangeContract" title="Contrato de câmbio" />

          <app-import-document
            v-if="hasInputAllowed() && editModeEnabled && principalAmountValue > 10000"
            id="docusign"
            name="docusign"
            class="col-12"
            @fileSelected="handleDocusign"
            accept=".pdf"
            :loading="loadingUpload"
          />

          <div
            class="col-12 mt-1"
            v-if="uploadedDocusignFileUrls.length > 0"
            @click="openDocusign()"
          >
            <div class="status-docusign">
              <div>
                <i class="bi bi-file-earmark-text-fill"></i> Contrato para envio:
                {{ uploadedDocusignFileUrls[0].name }}
              </div>
            </div>
          </div>

          <div v-if="uploadedDocusignFileUrls.length > 0" class="docusignFiles">
            <form-input
              className="col-12 form-input"
              label="Nome do responsável"
              v-model="documentNameValue"
              id="documentName"
              name="documentName"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="E-mail de retorno"
              v-model="emailSubjectValue"
              id="emailSubject"
              name="emailSubject"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="Nome de que irá assinar"
              v-model="signerNameValue"
              id="signerName"
              name="signerName"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              className="col-12 form-input"
              label="Email do cliente"
              v-model="signerEmailValue"
              id="signerEmail"
              name="signerEmail"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-input
              v-if="financialInstitutionValue === 'BS2'"
              className="col-12 form-input"
              label="ID do contrato"
              v-model="idContractValue"
              id="idContract"
              name="idContract"
              type="text"
              :isDisabled="!hasInputAllowed()"
            />

            <form-button
              class="col-12 form-input"
              title="Enviar contrato para assinatura"
              :loading="loadingSubmitDocusign"
              :isDisabled="!hasInputAllowed()"
              @clicked="onSubmitDocusign"
            />
          </div>

          <app-title v-if="!editModeEnabled" label="DADOS BANCÁRIOS DO BENEFICIÁRIO" />

          <app-bank-list
            v-if="bankOptions.length > 0 && !editModeEnabled"
            v-model="bankChangeValue"
            :clientId="clientId"
            :initialBanks="bankOptions"
            :isDisabled="bankOptions.length === 0"
            @edit="updateBank"
          />

          <div v-if="exchangeRecipient && bankChangeValue != ''" class="col-12 mt-1 mb-4">
            <div class="operation">
              <div class="row">
                <div class="col-6">
                  <label>Nome:</label>
                  <p>{{ exchangeRecipient?.name }}</p>
                </div>

                <div class="col-6">
                  <label>País:</label>
                  <p>{{ exchangeRecipient?.country }}</p>
                </div>

                <div class="col-12">
                  <label>Banco:</label>
                  <p>{{ exchangeRecipient?.bank }}</p>
                </div>

                <div class="col-6">
                  <label>Conta:</label>
                  <p>{{ exchangeRecipient?.account }}</p>
                </div>

                <div class="col-6">
                  <label>SWIFT:</label>
                  <p>{{ exchangeRecipient?.swift }}</p>
                </div>

                <div class="col-6">
                  <label>Banco intermediário:</label>
                  <p>
                    {{ exchangeRecipient?.intermediaryBankAccount?.name || emptyPlaceholder }}
                  </p>
                </div>

                <div class="col-6">
                  <label>Conta:</label>
                  <p>
                    {{ exchangeRecipient?.intermediaryBankAccount?.account || emptyPlaceholder }}
                  </p>
                </div>

                <div class="col-6">
                  <label>SWIFT:</label>
                  <p>
                    {{ exchangeRecipient?.intermediaryBankAccount?.swift || emptyPlaceholder }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            label="Instituição Financeira"
            v-model="financialInstitutionValue"
            :items="financialInstitutionOptions"
            :isDisabled="!hasInputAllowed() || financialInstitutionOptions.length === 0"
            id="financialInstitution"
            name="financialInstitution"
          />

          <form-select
            v-if="!editModeEnabled"
            class="col-12 form-input"
            :label="tedFinancialInstitutionLabel"
            v-model="tedFinancialInstitutionValue"
            :items="tedFinancialInstitutionOptions"
            id="tedFinancialInstitution"
            name="tedFinancialInstitution"
            :isDisabled="!hasInputAllowed() || tedFinancialInstitutionOptions.length === 0"
          />

          <form-select
            v-if="editModeEnabled"
            class="col-12 form-input"
            label="Status"
            v-model="statusValue"
            :items="posStatusOptions"
            id="status"
            name="status"
            :isDisabled="!hasInputAllowed()"
          />

          <form-button
            class="col-12 form-input"
            title="Enviar"
            :loading="loading"
            :isDisabled="!hasInputAllowed() || isLegacyOperation"
            @clicked="onSubmitAction"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.status-docusign {
  color: var(--sixth-color);
  background-color: rgba(225, 225, 225, 0.2);
  border-radius: 9px;
  padding: 15px;
  font-size: 14px;
  line-height: 28px;

  p {
    color: var(--sixth-color);
    margin-bottom: 0;
    font-size: 14px;
  }
}
.discount-detail {
  color: var(--sixth-color);
  background-color: rgb(0 0 0 / 7%);
  border: solid 1px rgb(0 0 0 / 15%);
  border-radius: 9px;
  font-size: 12px;
  width: 100%;
  border-collapse: separate;
  border: solid #ccc 1px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;

  th {
    padding: 26px 26px 5px 26px;
  }

  td {
    padding: 10px 26px;
  }

  tbody {
    tr:last-child {
      td {
        padding: 10px 26px 26px 26px;
      }
    }
  }
}
.operation {
  color: var(--sixth-color);
  background-color: rgb(0 0 0 / 7%);
  border: solid 1px rgb(0 0 0 / 15%);
  border-radius: 9px;
  padding: 26px 20px 10px 25px;
  font-size: 14px;
  line-height: 28px;

  p {
    padding: 0;
    font-weight: 600;
  }
}
</style>
