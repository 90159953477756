<script>
import { ref, watch } from 'vue'
import { toast } from 'vue3-toastify'

import formInput from '@/components/form/input.vue'
import appTitle from '@/components/shared/title.vue'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appAddress',

  components: {
    appTitle,
    formInput
  },
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const cepValue = ref(props.address.cep)
    const streetValue = ref(props.address.street)
    const numberValue = ref(props.address.number)
    const complementValue = ref(props.address.complement)
    const cityValue = ref(props.address.city)
    const neighborhoodValue = ref(props.address.neighborhood)
    const stateValue = ref(props.address.state)

    const updateAndEmit = () => {
      const addressInfo = {
        cep: cepValue.value,
        street: streetValue.value,
        number: numberValue.value,
        complement: complementValue.value,
        city: cityValue.value,
        neighborhood: neighborhoodValue.value,
        state: stateValue.value
      }
      emit('address', addressInfo)
    }

    watch(
      [
        cepValue,
        streetValue,
        numberValue,
        complementValue,
        cityValue,
        neighborhoodValue,
        stateValue
      ],
      updateAndEmit,
      { deep: true }
    )

    const searchForZIP = async (cepValue) => {
      try {
        const response = await fetch(`https://opencep.com/v1/${cepValue}`)
        const data = await response.json()

        if (data.error) {
          toast('Não foi possível encontra o endereço pelo CEP, verifique ou digite manualmente', {
            theme: 'auto',
            type: 'warning',
            dangerouslyHTMLString: true
          })
          return
        }

        streetValue.value = data.logradouro
        cityValue.value = data.localidade
        neighborhoodValue.value = data.bairro
        stateValue.value = data.uf
      } catch (e) {
        handleGlobalError(e)
      }
    }
    return {
      cepValue,
      streetValue,
      numberValue,
      complementValue,
      cityValue,
      neighborhoodValue,
      stateValue,

      searchForZIP
    }
  }
}
</script>

<template>
  <div class="row g-2">
    <app-title label=" ENDEREÇO" />

    <form-input
      className="col-12 form-input"
      label="CEP"
      v-model="cepValue"
      id="cep"
      name="cep"
      type="text"
      @focusout="searchForZIP(cepValue)"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-8 form-input"
      label="Endereço"
      v-model="streetValue"
      id="street"
      name="street"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Número"
      v-model="numberValue"
      id="number"
      name="number"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Cidade"
      v-model="cityValue"
      id="city"
      name="city"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Bairro"
      v-model="neighborhoodValue"
      id="neighborhood"
      name="neighborhood"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 col-md-4 form-input"
      label="Estado"
      v-model="stateValue"
      id="state"
      name="state"
      type="text"
      :isDisabled="isDisabled"
    />

    <form-input
      className="col-12 form-input"
      label="Complemento"
      v-model="complementValue"
      id="complement"
      name="complement"
      type="text"
      :isDisabled="isDisabled"
    />
  </div>
</template>
