<template>
  <div class="discounts-container">
    <!-- Tabela para PROMOCODE -->
    <div v-if="hasDiscounts('PROMOCODE')" class="col-12 table-discounts">
      <app-title label="PROMOCODE" />
      <table>
        <thead>
          <tr>
            <th v-for="column in discountsConfig.PROMOCODE" :key="column">{{ column.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in filteredDiscounts('PROMOCODE')" :key="discount.clientId">
            <td v-for="column in discountsConfig.PROMOCODE" :key="column">
              {{ hasDiscountValue(discount[column.field]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tabela para INDICATE_AND_WIN -->
    <div v-if="hasDiscounts('INDICATE_AND_WIN')" class="col-12 table-discounts">
      <app-title label="INDIQUE E GANHE" />
      <table>
        <thead>
          <tr>
            <th v-for="column in discountsConfig.INDICATE_AND_WIN" :key="column">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in filteredDiscounts('INDICATE_AND_WIN')" :key="discount.clientId">
            <td v-for="column in discountsConfig.INDICATE_AND_WIN" :key="column">
              {{ hasDiscountValue(discount[column.field]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tabela para INDICATE_AND_WIN_FIRST_TIME_OPERATION -->
    <div
      v-if="hasDiscounts('INDICATE_AND_WIN_FIRST_TIME_OPERATION')"
      class="col-12 table-discounts"
    >
      <app-title label="INDIQUE E GANHE NA PRIMEIRA OPERAÇÃO" />
      <table>
        <thead>
          <tr>
            <th
              v-for="column in discountsConfig.INDICATE_AND_WIN_FIRST_TIME_OPERATION"
              :key="column"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="discount in filteredDiscounts('INDICATE_AND_WIN_FIRST_TIME_OPERATION')"
            :key="discount.clientId"
          >
            <td
              v-for="column in discountsConfig.INDICATE_AND_WIN_FIRST_TIME_OPERATION"
              :key="column"
            >
              {{ hasDiscountValue(discount[column.field]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tabela para PROMOCODE_GIFT_CARD -->
    <div v-if="hasDiscounts('PROMOCODE_GIFT_CARD')" class="col-12 table-discounts">
      <app-title label="GIFT CARD" />
      <table>
        <thead>
          <tr>
            <th v-for="column in discountsConfig.PROMOCODE_GIFT_CARD" :key="column">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="discount in filteredDiscounts('PROMOCODE_GIFT_CARD')" :key="discount.clientId">
            <td v-for="column in discountsConfig.PROMOCODE_GIFT_CARD" :key="column">
              {{ hasDiscountValue(discount[column.field]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import appTitle from '@/components/shared/title.vue'

export default {
  name: 'tableDiscounts',

  components: {
    appTitle
  },

  props: {
    discounts: {
      type: Array,
      default: () => []
    }
  },

  setup(props) {
    const discountsConfig = {
      PROMOCODE: [
        { label: 'CLIENTE', field: 'clientId' },
        { label: 'PROMOCODE', field: 'promoCode' },
        { label: 'SPREAD (%)', field: 'spreadPercentage' },
        { label: 'TARIFA (%)', field: 'clientTaxPercentage' }
      ],
      INDICATE_AND_WIN: [
        { label: 'CLIENTE', field: 'clientId' },
        { label: 'SPREAD (%)', field: 'spreadPercentage' },
        { label: 'TARIFA (%)', field: 'clientTaxPercentage' },
        { label: 'QUANT. UTILIZADA', field: 'accumulation' }
      ],
      INDICATE_AND_WIN_FIRST_TIME_OPERATION: [
        { label: 'CLIENTE', field: 'clientId' },
        { label: 'SPREAD (%)', field: 'spreadPercentage' },
        { label: 'TARIFA (%)', field: 'clientTaxPercentage' }
      ],
      PROMOCODE_GIFT_CARD: [
        { label: 'CLIENTE', field: 'clientId' },
        { label: 'PROMOCODE', field: 'promoCode' }
      ]
    }

    const filteredDiscounts = (type) => {
      return props.discounts.filter((discount) => {
        if (discount.type != null) {
          return discount.type === type
        } else {
          // TODO: Se o type não está definido, verificar se é PROMOCODE ou INDICATE_AND_WIN baseado na existência de promoCode, pois é legado.
          return (
            (type === 'PROMOCODE' && discount.promoCode) ||
            (type === 'INDICATE_AND_WIN' && !discount.promoCode)
          )
        }
      })
    }

    const hasDiscounts = (type) => {
      return filteredDiscounts(type).length > 0
    }

    const hasDiscountValue = (discount) => {
      return discount !== null && discount !== undefined ? discount : 'N/A'
    }

    return {
      discountsConfig,
      filteredDiscounts,
      hasDiscounts,
      hasDiscountValue
    }
  }
}
</script>

<style scoped lang="scss">
.table-discounts {
  margin-bottom: 20px;
  table {
    tbody {
      tr {
        td {
          color: var(--label-color);
        }
      }
    }
  }
}
</style>
