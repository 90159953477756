import { handleGlobalError } from '@/services/errorHandler'

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/'
      })

      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing
        if (newWorker) {
          console.debug('Service worker installing')

          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed') {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                newWorker.postMessage({ type: 'SKIP_WAITING' })
                console.debug('Service worker installed')
              }
            }
          })
        }
      })

      if (registration.waiting) {
        if (navigator.serviceWorker.controller) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          console.debug('Service worker installed')
        }
      }

      if (registration.active) {
        console.debug('Service worker active')
      }
    } catch (e) {
      handleGlobalError(e, 'Registration failed with')
    }
  }
}
