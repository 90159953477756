import axios from 'axios'

import {
  authorizationHeaderInterceptor,
  forbiddenOrTokenExpiredInterceptor,
  adminTransformInterceptor
} from './configureInterceptors'

const ApiName = {
  ADMINS: 'admins',
  BROKERS: 'brokers',
  LOGIN: 'login',
  CONFIGS: 'configs',
  PTAXES: 'ptaxes',
  BANKS: 'banks',
  OPERATIONS: 'operations',
  DOCUSIGN: 'docusign'
}

class ApiClient {
  constructor() {
    const { VITE_APP_URL } = process.env

    this.apiClient = axios.create({
      baseURL: `${VITE_APP_URL}`
    })

    authorizationHeaderInterceptor(this.apiClient.interceptors.request)
    forbiddenOrTokenExpiredInterceptor(this.apiClient.interceptors.response)

    adminTransformInterceptor(this.apiClient.interceptors.response)
  }

  get(apiName, resource, config) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.get(endpoint, { params: config })
  }

  post(apiName, resource, data, additionalHeaders = {}) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.post(endpoint, data, {
      headers: { ...additionalHeaders }
    })
  }

  patch(apiName, resource, data, additionalHeaders = {}) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.patch(endpoint, data, {
      headers: { ...additionalHeaders }
    })
  }

  put(apiName, resource, data, additionalHeaders = {}) {
    const endpoint = resource ? `/${apiName}/${resource}` : `/${apiName}`
    return this.apiClient.put(endpoint, data, {
      headers: { ...additionalHeaders }
    })
  }

  delete(apiName, resource, id) {
    const endpoint = resource ? `/${apiName}/${resource}/${id}` : `/${apiName}/${id}`
    return this.apiClient.delete(endpoint)
  }
}

const apiClient = new ApiClient()

export const checkEmailStatus = (email) => {
  return apiClient.get(ApiName.LOGIN, `status/${email}`)
}

export const login = (data) => {
  return apiClient.post(ApiName.LOGIN, '', data)
}

export const fetchDashboard = () => {
  return apiClient.get(ApiName.ADMINS, 'onboardings/dashboard')
}

export const fetchTargetTax = (config) => {
  return apiClient.get(ApiName.ADMINS, 'targetTaxes', config)
}

export const deleteTargetTax = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/targetTaxes', id)
}

export const fetchOpenOperations = (config) => {
  return apiClient.get(ApiName.ADMINS, 'operations', config)
}

export const createOpenOperation = (data) => {
  return apiClient.post(ApiName.ADMINS, 'operations', data)
}

export const fetchPosOperations = (config) => {
  return apiClient.get(ApiName.ADMINS, 'operations/pos', config)
}

export const fetchCompletedOperations = (config) => {
  return apiClient.get(ApiName.ADMINS, 'operations/pos/completed', config)
}

export const fetchClients = (config) => {
  return apiClient.get(ApiName.ADMINS, 'clients', config)
}

export const fetchClient = (id) => {
  return apiClient.get(ApiName.ADMINS, `clients/${id}`)
}

export const updateClient = (data) => {
  return apiClient.put(ApiName.ADMINS, 'clients', data)
}

export const deleteClient = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/clients', id)
}

export const fetchClientOnboarding = (id) => {
  return apiClient.get(ApiName.ADMINS, `onboardings/clients/${id}`)
}

export const updateClientOnboarding = (id, data) => {
  return apiClient.patch(ApiName.ADMINS, `onboardings/clients/${id}`, data)
}

export const updateDocsOnboarding = (id, data) => {
  return apiClient.patch(ApiName.ADMINS, `onboardings/clients/${id}/docs`, data)
}

export const fetchBanks = () => {
  return apiClient.get(ApiName.BANKS)
}

export const createUser = (data) => {
  return apiClient.post(ApiName.ADMINS, 'clients/users', data)
}

export const deleteUser = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/users', id)
}

export const sendUserClientInvite = (id) => {
  return apiClient.post(ApiName.ADMINS, `clients/users/${id}/invites`)
}

export const fetchHoliday = (config) => {
  return apiClient.get(ApiName.ADMINS, 'holidays', config)
}

export const createHoliday = (data) => {
  return apiClient.post(ApiName.ADMINS, 'holidays', data)
}

export const deleteHoliday = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'holidays', id)
}

export const fetchCountriesBanks = () => {
  return apiClient.get(ApiName.CONFIGS, 'banks')
}

export const fetchTypeByClientType = (config) => {
  return apiClient.get(ApiName.CONFIGS, 'natures', config)
}

export const fetchClientBank = (id) => {
  return apiClient.get(ApiName.ADMINS, `operations/recipients/${id}`)
}

export const fetchPreOperation = (id) => {
  return apiClient.get(ApiName.ADMINS, `operations/${id}`)
}

export const deletePreOperation = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'operations', id)
}

export const fetchPosOperation = (id) => {
  return apiClient.get(ApiName.ADMINS, `operations/pos/${id}`)
}

export const deletePosOperation = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/operations/pos', id)
}

export const fetchTedClientBank = (brokerId) => {
  return apiClient.get(ApiName.BROKERS, `${brokerId}`)
}

export const createPosOperation = (data) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos', data)
}

export const updatePosOperation = (data) => {
  return apiClient.put(ApiName.ADMINS, 'operations/pos', data)
}

export const uploadTed = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/docs/teds', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadDocument = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/docs', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadDarfAdmin = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/docs/darfs/admins', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadDarf = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/docs/darfs', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadExchangeContract = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/docs/contracts', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const uploadSwift = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/docs/swifts', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const createNewBank = (data) => {
  return apiClient.post(ApiName.ADMINS, 'clients/exchangeRecipients', data)
}

export const editBank = (id, data) => {
  return apiClient.patch(ApiName.ADMINS, `clients/exchangeRecipients/${id}`, data)
}

export const fetchPromoCodes = () => {
  return apiClient.get(ApiName.ADMINS, 'super/promoCodes')
}

export const updatePromoCode = (data) => {
  return apiClient.put(ApiName.ADMINS, 'super/promoCodes', data)
}

export const createPromoCode = (data) => {
  return apiClient.post(ApiName.ADMINS, 'super/promoCodes', data)
}

export const deletePromoCode = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/promoCodes', id)
}

export const fetchGiftCards = () => {
  return apiClient.get(ApiName.ADMINS, 'super/giftCards/pending')
}

export const fetchPlatformLogs = (config) => {
  return apiClient.get(ApiName.ADMINS, 'super/logs', config)
}

export const createGiftCardVoucher = (id, data) => {
  return apiClient.post(ApiName.ADMINS, `super/giftCards/${id}/voucher`, data)
}

export const fetchAndProcessMasks = () => {
  return apiClient.get(ApiName.ADMINS, 'super/spreads/default')
}

export const fetchVariation = () => {
  return apiClient.get(ApiName.ADMINS, 'super/variations')
}

export const fetchMarketStatus = () => {
  return apiClient.get(ApiName.CONFIGS, 'status/market')
}

export const fetchPTAX = (currency) => {
  return apiClient.get(ApiName.PTAXES, `${currency}/today`)
}

export const fetchNatureType = (config) => {
  return apiClient.get(ApiName.CONFIGS, 'natures', config)
}

export const fetchAlertMessage = () => {
  return apiClient.get(ApiName.CONFIGS, 'messages/alert')
}

export const updateAlertMessage = (data) => {
  return apiClient.patch(ApiName.ADMINS, 'super/messages/alert', data)
}

export const fetchUser = (config) => {
  return apiClient.get(ApiName.ADMINS, 'super/users', config)
}

export const createAdminUser = (data) => {
  return apiClient.post(ApiName.ADMINS, 'super/users', data)
}

export const updateAdminUser = (data) => {
  return apiClient.put(ApiName.ADMINS, 'super/users', data)
}

export const deleteAdminUser = (id) => {
  return apiClient.delete(ApiName.ADMINS, 'super/users', id)
}

export const recoverPassword = (data) => {
  return apiClient.post(ApiName.LOGIN, 'passwords/recover', data)
}

export const resetPassword = (data) => {
  return apiClient.patch(ApiName.LOGIN, 'passwords', data)
}

export const uploadDocumentOnboarding = (formData) => {
  return apiClient.post(ApiName.ADMINS, 'onboardings/docs', formData, {
    'Content-Type': 'multipart/form-data'
  })
}

export const fetchBrokers = () => {
  return apiClient.get(ApiName.BROKERS, '')
}

export const fetchOperationTaxe = (id, clientId, liquidationFC) => {
  return apiClient.get(
    ApiName.ADMINS,
    `operations/pos/fees?ids=${id}&clientId=${clientId}&liquidationFC=${liquidationFC}`
  )
}

export const createPosOperationHomeBroker = (body) => {
  return apiClient.post(ApiName.ADMINS, 'operations/pos/homeBroker', body)
}

export const createOperationTaxesHomeBroker = (body) => {
  return apiClient.post(ApiName.ADMINS, 'targetTaxes/homeBroker', body)
}

export const fetchRegistrationForm = (id) => {
  return apiClient.get(ApiName.ADMINS, `clients/${id}/registrationForm`)
}

export const updateRegistrationForm = (id, data) => {
  return apiClient.patch(ApiName.ADMINS, `clients/${id}/registrationForm`, data)
}

export const createDocsSignature = (data, clientId) => {
  return apiClient.post(
    ApiName.ADMINS,
    `onboardings/clients/${clientId}/docs/docusign/registrationForm`,
    data
  )
}

export const createPosDocsSignature = (data, posId) => {
  return apiClient.post(ApiName.ADMINS, `operations/pos/${posId}/docs/docusign/contracts`, data)
}
